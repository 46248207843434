<ng-container *ngIf="!!control && !!inputType">
  <div class="inline-flex flex-col items-start gap-5 w-full">
    <p class="w-full">{{customLabel ? customLabel : label}}</p>
    <input
      *ngIf="inputType === 'number'"
       [formControl]="control"
       [id]="inputId"
      type="number"
       [placeholder]="placeholder"
      (focus)="onFocus()"
       class="flex h-35 px-10 py-15 items-center gap-8 border border-dark/20 rounded-sm w-full"/>
    <input
      *ngIf="inputType !== 'number' && inputType !== 'date'"
       [formControl]="control"
       [id]="inputId"
       [type]="inputType"
       [placeholder]="placeholder"
      (focus)="onFocus()"
       class="flex h-35 px-10 py-15 items-center gap-8 border border-dark/20 rounded-sm w-full"/>
    <input
      *ngIf="inputType === 'date'"
       [formControl]="control"
       [id]="inputId"
       type="date"
       [placeholder]="placeholder"
      (focus)="onFocus()"
       class="flex h-35 px-10 py-15 items-center gap-8 border border-dark/20 rounded-sm w-full"/>
    <div *ngIf="control.invalid && (control.dirty || control.touched) && !controlBlurred" class="text-12 text-red">
      <div *ngIf="control.hasError('required')">{{label}} is required</div>
      <div *ngIf="control.hasError('minLength')">{{label}} doesn't have enough characters</div>
      <div *ngIf="control.hasError('maxLength')">{{label}} has too many characters</div>
      <div *ngIf="control.hasError('max')">{{getMaxMessage()}}</div>
      <div *ngIf="control.hasError('email')">Invalid email</div>
      <div *ngIf="control.hasError('minLength')">{{getMinMessage()}}</div>
    </div>
  </div>
</ng-container>
