export const environment = {
  production: true,
  firebaseConfig: {
    apiKey: "AIzaSyBObvX-9WSaTZMC4miBm5AVanu-7-cObgE",
    authDomain: "tandem-finance-demo.firebaseapp.com",
    projectId: "tandem-finance-demo",
    storageBucket: "tandem-finance-demo.appspot.com",
    messagingSenderId: "552243127386",
    appId: "1:552243127386:web:3201e4670d726ee957a463",
    measurementId: "G-FN41L1PMBH"
  },
  environmentName: 'Demo - Avilia',
  stripePublicKey: 'pk_test_51NuT7kFF3o6u8UKU7BXXiwo3lWL4i9CHK3LfgAlEehWGVesiUJlULMZ4EDagtlLFnRkP2sudcmxfcP6hlbKjVMHX00NowHOdXg',
  //functionsPath: 'http://127.0.0.1:5001/tandem-finance-prd/us-central1',
  functionsPath: 'https://us-central1-dime-platform-33.cloudfunctions.net',
  logoPath: 'assets/images/worth-the-happiness.png',
  whiteLogoPath: 'assets/images/worth-the-happiness.png',
  whiteIconPath: 'assets/svg/logo/icon_white.svg',
  defaultTheme: 'worth-happiness',
  enableGridDisplay: false,
  defaultWelcomeURL: 'https://www.youtube.com/embed/XxFuLNn55zs',
  coachVideoURL: 'https://www.youtube.com/embed/bDLwOTPUThk',
  defaultFPid: 'GjJqgcBzo3tLLG8IVpK6',
  defaultCFid: 'S24TCb2YD3d4SOxlje36',
  defaultSPid: 'VVwgznZUEGUvPyAfi8qF',
};
