<div class="w-full" *ngIf="tableConfiguration">
  <div class="justify-between flex items-start">
    <h2 class="mb-15" *ngIf="tableConfiguration.title">{{ tableConfiguration.title }}</h2>
    <div class="flex flex-row gap-5">
      <h3 class="mb-15" *ngIf="tableConfiguration.subtitle">{{ tableConfiguration.subtitle }}</h3><tandem-tooltip *ngIf="tableConfiguration.hoverHint" [text]="tableConfiguration.hoverHint"></tandem-tooltip>
    </div>
    <!--    <button class="text-primary font-medium text-12" *ngIf="tableConfiguration.canBeHidden">Hide</button>-->
    <div *ngIf="tableConfiguration.collapsible" class="flex flex-col justify-start h-full hover:cursor-pointer">
      <tandem-icon icon="upload-circle" [width]="35" [height]="30" (click)="toggleCollapse()" [ngClass]="{'rotate-180': !tableConfiguration.collapsed}"></tandem-icon>
    </div>
  </div>
  <div [@expandCollapse]="tableConfiguration.collapsed ? 'collapsed' : 'expanded'">
    <table [ngClass]="tableClass" class="table w-full">
      <tr
        class="flex border-b-[1px] border-dark/20 pb-10 mb-25 items-center justify-between w-full ![&>th:first-child]:justify-start ![&>th:last-child]:justify-end"
      >
        <head-item *ngFor="let col of tableConfiguration.columns" [sortable]="col.sortable" [sorted]="col.sorted" (onSort)="onSort($event, col)" [sortedByDefault]="!!col.sortedByDefault">{{col.title}}</head-item>
        <head-item *ngIf="!!tableConfiguration.editRowFunction || !!tableConfiguration.deleteRowFunction">Actions</head-item>
      </tr>
      <div class="flex flex-col gap-y-5">
        <!--      <line-item type="draggable" [hasCheckbox]="true">-->
        <!--        <td>stuff</td>-->
        <!--        <td>stuff</td>-->
        <!--        <td>stuff</td>-->
        <!--        <td>stuff</td>-->
        <!--      </line-item>-->
        <!--      <line-item [hasCheckbox]="true">-->
        <!--        <td>stuff</td>-->
        <!--        <td>stuff</td>-->
        <!--        <td>stuff</td>-->
        <!--        <td>stuff</td>-->
        <!--      </line-item>-->
        <div *ngIf="tableConfiguration.hasCheckbox" class="flex flex-row justify-start items-center gap-20 mb-20 relative">
          <tandem-form-checkbox *ngIf="tableConfiguration.hasCheckbox && data.length > 0" id="mass-select" (checkboxToggled)="massToggle($event)" [control]="massCheckControl" [label]="massCheckControl.value ? 'Deselect All' : 'Select All'" labelPosition="after"></tandem-form-checkbox>
          <ng-container *ngIf="selectedData.length > 0">
            <div class="relative">
              <tandem-button theme="outline" color="primary" class="example-standalone-trigger" (click)="toggleDropdown($event)">Move to</tandem-button>
              <div #dropdownMenu *ngIf="moveDropdownVisible" class="example-menu" cdkMenu [ngClass]="{'display-none': !moveDropdownVisible}">
                <button *ngFor="let option of moveOptions" (click)="onMove(option)" class="example-menu-item" cdkMenuItem>{{option}}</button>
              </div>
<!--              <div-->
<!--                #dropdownMenu-->
<!--                *ngIf="moveDropdownVisible"-->
<!--                class="absolute top-full right-0 mt-2 flex flex-col gap-5 px-8 py-16 rounded-xs bg-dark/20 z-50 tandem-dropdown shadow-lg w-fit"-->
<!--                (click)="$event.stopPropagation()"-->
<!--              >-->
<!--                <h5 *ngFor="let option of moveOptions" class="hover:cursor-pointer" (click)="onMove(option)">{{option}}</h5>-->
<!--              </div>-->
            </div>
            <tandem-button theme="outline" color="red" [onClick]="onDelete.bind(this)">Delete</tandem-button>
          </ng-container>
        </div>
        <ng-container *ngIf="tableConfiguration.draggable">
          <div cdkDropListGroup>
            <div cdkDropList [cdkDropListData]="data" [id]="tableId || 'noId'" [cdkDropListConnectedTo]="connectedDropLists" (cdkDropListDropped)="drop($event)" class="drop-list-placeholder" [class.dragging-over]="isDraggingItem">
              <line-item
                class="example-box"
                [hasCheckbox]="tableConfiguration.hasCheckbox"
                [massCheckObservable]="massCheckObservable$"
                *ngFor="let row of data"
                cdkDrag
                [hasMeatball]="hasMeatball"
                [ngClass]="{'hover:cursor-pointer hover:bg-primary/10': !!tableConfiguration.onRowClick}"
                (click)="performAction(row)"
                (onEdit)="onTrigger(row, 'edit')"
                (onMove)="onTrigger(row, 'move')"
                (onDelete)="onTrigger(row, 'delete')"
                [type]="tableConfiguration.draggable ? 'draggable' : ''"
                [showMove]="!!tableConfiguration.moveRowFunction"
              (cdkDragStarted)="onDragStart($event)"
              (cdkDragEnded)="onDragEnd($event)">
                <td *ngFor="let col of tableConfiguration.columns; let isLast = last" class="flex flex-row items-center" [class.justify-end]="isLast && !hasMeatball">
                  <img *ngIf="col.getImageURL" [src]="col.getImageURL(row)" referrerpolicy="no-referrer" class="mr-10 h-27 w-27 rounded-full">
                  {{getDisplayForElement(row, col)}}
                  <svg class="ml-10" *ngIf="col.columnType === 'status' && col.itemProperty" xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                    <circle  cx="5" cy="5" r="5" [attr.fill]="getStatusHex(row[col.itemProperty])"/>
                    <!--            <path *ngIf="row[col.itemProperty] === 'pending'" d="M5 10C2.2385 10 0 7.7615 0 5C0 2.2385 2.2385 0 5 0C7.7615 0 10 2.2385 10 5C10 7.7615 7.7615 10 5 10ZM5.5 5V2.5H4.5V6H7.5V5H5.5Z" fill="#636C7A"/>-->
                  </svg>
                </td>
              </line-item>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="!tableConfiguration.draggable">
          <line-item
            *ngFor="let row of data"
            [hasMeatball]="hasMeatball"
            [hasCheckbox]="tableConfiguration.hasCheckbox"
            [massCheckObservable]="massCheckObservable$"
            (onCheckboxToggle)="onCheckboxToggle($event, row)"
            [ngClass]="{'hover:cursor-pointer hover:bg-primary/10': !!tableConfiguration.onRowClick}"
            (click)="performAction(row)"
            (onEdit)="onTrigger(row, 'edit')"
            (onDelete)="onTrigger(row, 'delete')"
            [type]="tableConfiguration.draggable ? 'draggable' : ''"
            [showMove]="!!tableConfiguration.moveRowFunction">
            <td *ngFor="let col of tableConfiguration.columns; let isLast = last" class="flex flex-row items-center" [class.justify-end]="isLast && !hasMeatball">
              <img *ngIf="col.getImageURL" [src]="col.getImageURL(row)" referrerpolicy="no-referrer" class="mr-10 h-27 w-27 rounded-full">
              {{getDisplayForElement(row, col)}}
              <svg class="ml-10" *ngIf="col.columnType === 'status' && col.itemProperty" xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                <circle  cx="5" cy="5" r="5" [attr.fill]="getStatusHex(row[col.itemProperty])"/>
                <!--            <path *ngIf="row[col.itemProperty] === 'pending'" d="M5 10C2.2385 10 0 7.7615 0 5C0 2.2385 2.2385 0 5 0C7.7615 0 10 2.2385 10 5C10 7.7615 7.7615 10 5 10ZM5.5 5V2.5H4.5V6H7.5V5H5.5Z" fill="#636C7A"/>-->
              </svg>
            </td>
          </line-item>
        </ng-container>
        <div cdkDropList (cdkDropListDropped)="drop($event)" *ngIf="tableConfiguration?.draggable">

        </div>
        <div *ngIf="data.length === 0" class="flex items-center">
          <span class="text-19 font-medium">{{tableConfiguration.noDataMessage}}</span>
        </div>
      </div>
    </table>
  </div>
  <tandem-pagination *ngIf="tableConfiguration.pageSizeOptions" [pages]="tableConfiguration.pageSizeOptions" />
  <add-new-row [total]="total" [displayTotal]="shouldDisplayTotal()" [displayButton]="!!tableConfiguration.addFunction" (onAdd)="onAdd()"/>
</div>



<!--if 5px gap, use gap-5 and it will convert to rem auto-->

<!--opacity is unique, can't use normal variables. cant use slash notation-->
