import {Component, OnInit} from '@angular/core';
import {AuthService} from "../../services/auth.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";

@Component({
  selector: 'tandem-complete-registration',
  templateUrl: './complete-registration.component.html',
  styleUrls: ['./complete-registration.component.scss']
})
export class CompleteRegistrationComponent implements OnInit{

  public completeRegistrationForm: FormGroup = new FormGroup<any>({});

  constructor(private auth: AuthService,
              private fb: FormBuilder) {
  }
  ngOnInit(): void {
    this.completeRegistrationForm = this.fb.group({
      firstName: [null, Validators.required],
      lastName: [null, Validators.required],
      birthday: [null, Validators.required],
    })
  }

}
