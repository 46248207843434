import {Component, Input, OnInit} from '@angular/core';
import {AbstractControl, FormControl, FormGroup} from "@angular/forms";

@Component({
  selector: 'tandem-form-input',
  templateUrl: './form-input.component.html',
  styleUrls: ['./form-input.component.scss']
})
export class FormInputComponent implements OnInit {

  @Input() label: string = '';
  @Input() customLabel?: string;
  @Input() form: FormGroup | AbstractControl = new FormGroup({});
  @Input() inputId: string = '';
  @Input() inputType: string = 'text';
  @Input() placeholder = '';
  @Input() isDisabled = false;
  @Input() controlName: string = '';

  @Input() onFocus: () => void = () => {};

  control: FormControl = new FormControl();
  controlBlurred = false;

  constructor() {
  }

  ngOnInit() {
    this.control = this.form.get(this.controlName) as FormControl;
  }

  getMinMessage() {
    if (this.control.getError('min')['min'] === 0) {
      return `${this.label} can't be negative`;
    } else {
      return `${this.label} must be at least ${this.control.getError('min')['min']} characters`;
    }
  }

  getMaxMessage() {
      return `${this.label} cannot be greater than ${this.control.getError('max')['max']}`;
    }


}
