import {
  Component, ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {
  PageModel,
  TableColumn,
  TableConfiguration,
} from '../../models/table-configuration';
import {CdkDragDrop, CdkDragEnd, CdkDragStart, moveItemInArray, transferArrayItem} from "@angular/cdk/drag-drop";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {CoreService} from "../../services/core.service";
import {BehaviorSubject} from "rxjs";
import {FormControl} from "@angular/forms";

@Component({
  selector: 'tandem-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.css'],
  animations: [
    trigger('expandCollapse', [
      state('collapsed', style({
        height: '0px',
        opacity: 0,
        overflow: 'hidden'
      })),
      state('expanded', style({
        height: '*',
        opacity: 1
      })),
      transition('expanded <=> collapsed', animate('300ms ease-out'))
    ])
    ],
})
export class TableComponent<T> implements OnInit, OnChanges {
  @Input() tableConfiguration: TableConfiguration<T> | null = null;
  // @Input() tableConfiguration: TableConfiguration<T> = {
  //   rowActions: [],
  //   columns: [],
  //   noDataMessage: 'No results found',
  // };
  @Input() data: T[] = [];
  @Input() tableClass?: string;
  @Input() title?: string | undefined = undefined;
  @Input() tableId?: string;
  @Input() moveOptions: string[] = ['Active Income', 'Passive Income', 'Unexpected Revenue'];

  total: number = 0;
  hasMeatball = false;

  selectedItemCount: number | undefined;
  selectedPageIndex: number | undefined;
  connectedDropLists: string[] = [];
  isDraggingItem: boolean = false;

  selectedData: T[] = [];

  massCheckControl: FormControl = new FormControl(false)
  massCheckSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  massCheckObservable$ = this.massCheckSubject.asObservable();
  moveDropdownVisible = false;

  @ViewChild('dropdownMenu', {read: ElementRef}) dropdownMenu: ElementRef | undefined;

  @Output() onPageModelUpdate: EventEmitter<PageModel<T>> = new EventEmitter<PageModel<T>>();
  @Output() selectedDataChanged: EventEmitter<T[]> = new EventEmitter<T[]>();
  @Output() selectedItemsMoved: BehaviorSubject<string | undefined> = new BehaviorSubject<string | undefined>(undefined);
  @Output() selectedItemsDeleted: BehaviorSubject<string | undefined> = new BehaviorSubject<string | undefined>(undefined);

  constructor(private coreService: CoreService) {
  }

  ngOnInit(): void {
    this.hasMeatball = !!this.tableConfiguration?.editRowFunction || !!this.tableConfiguration?.deleteRowFunction;
    if (this.tableConfiguration?.pageSizeOptions) {
      this.selectedItemCount = this.tableConfiguration?.pageSizeOptions[0];
    }
    if (this.tableConfiguration?.draggable) {
      if (this.tableId) {
        this.coreService.registerDropList(this.tableId)
      }
      this.coreService.isDragging.subscribe(isDragging => {
        this.isDraggingItem = isDragging;
      });

      this.coreService.dropLists.subscribe(lists => {
        // Filter out the current tableId to avoid connecting to itself
        this.connectedDropLists = lists.filter(id => id !== this.tableId);
      });
      this.coreService.dataChange.subscribe(changed => {
        this.data = [...this.data];
      })
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.tableConfiguration?.totalProp) {
      let total = 0;
      this.data.forEach(d => {
        // @ts-ignore
        let num: number = d[this.tableConfiguration!.totalProp] as number;
        total += num;
      });
      this.total = total;
    }
  }

  performAction(row: T) {
    if (!!this.tableConfiguration?.onRowClick) {
      this.tableConfiguration.onRowClick(row);
    }
  }

  drop(event: CdkDragDrop<T[]>): void {
    if (event.previousContainer === event.container) {
      moveItemInArray(this.data, event.previousIndex, event.currentIndex);
      // Manually trigger the update
      this.data = JSON.parse(JSON.stringify(this.data));
      this.coreService.dataChange.next();
    } else {
      // Perform the transfer manually
      const item = event.previousContainer.data[event.previousIndex];
      event.previousContainer.data.splice(event.previousIndex, 1);
      event.container.data.splice(event.currentIndex, 0, item);

      // Manually trigger the update for both containers
      if (this.tableId === event.previousContainer.id) {
        this.data = [...event.previousContainer.data];
      } else if (this.tableId === event.container.id) {
        this.data = [...event.container.data];
      }

      // If you need to update the parent component or sibling components,
      // emit an event or call a method to propagate the changes
      this.notifyDataChange();
    }
  }

  notifyDataChange(): void {
    // Emit an event or call a method to update the parent component
    this.coreService.dataChange.next();
  }

  onDragStart(event: CdkDragStart) {
    this.coreService.startDrag();
  }

  onDragEnd(event: CdkDragEnd) {
    this.coreService.stopDrag();
  }

  onTrigger(row: T, type: 'delete' | 'edit' | 'move') {
    if (type === 'delete' && this.tableConfiguration?.deleteRowFunction) {
      this.tableConfiguration.deleteRowFunction(row);
    }
    if (type === 'edit' && this.tableConfiguration?.editRowFunction) {
      this.tableConfiguration.editRowFunction(row);
    }
    if (type === 'move' && this.tableConfiguration?.moveRowFunction) {
      this.tableConfiguration.moveRowFunction(row);
    }
    this.coreService.dataChange.next();
  }

  getDisplayForElement(row: T, col: TableColumn<T>): string {
    if (!!col.displayMask) {
      return col.displayMask(row);
    } else {
      return String(row[col.itemProperty]);
    }
  }

  onAdd() {
    if (!!this.tableConfiguration?.addFunction) {
      this.tableConfiguration.addFunction();
    }
  }

  getStatusHex(status: any) {
    switch (status) {
      case 'Active':
        return '#56E07D';
      case 'Paused':
        return '#2B56F0';
      case 'Pending':
        return '#636C7A';
    }
    return '';
  }

  onSort(sortDirection: 'asc' | 'desc' | undefined, sortedCol: TableColumn<T>) {
    if (sortedCol.sortable) {
      this.tableConfiguration?.columns.forEach(column => {
        if (this.tableConfiguration?.columns.indexOf(column) !== this.tableConfiguration?.columns.indexOf(sortedCol)) {
          column.sorted = false;
        }
      });
      sortedCol.sorted = true;
    }
    this.onPageModelUpdate.emit({
      count: this.selectedItemCount,
      offset: (this.selectedItemCount && this.selectedPageIndex) ? this.selectedItemCount * this.selectedPageIndex : undefined,
        // TODO do this: lastDocument: null
      sortOrder: sortDirection || undefined,
      sortProperty: sortedCol.itemProperty || undefined,
      pageNumber: this.selectedPageIndex,
    })
  }

  toggleCollapse() {
    if (this.tableConfiguration?.collapsible) {
      this.tableConfiguration.collapsed = !this.tableConfiguration.collapsed;
    }
  }

  shouldDisplayTotal(): boolean {
    return !!this.tableConfiguration?.totalProp &&
      (this.tableConfiguration.collapsed || this.total > 0);
  }


  onCheckboxToggle($event: boolean, row: T) {
    console.log('hehe')
    if ($event) {
      if (!this.selectedData.includes(row)) {
        this.selectedData.push(row);
      }
    } else {
      let i = this.selectedData.indexOf(row);
      if (i !== -1) {
        this.selectedData.splice(i, 1);
      }
    }
    this.selectedDataChanged.emit(this.selectedData);
  }

  massToggle($event: boolean) {
    this.massCheckSubject.next($event);
  }

  onMove(option: string) {
    this.moveDropdownVisible = false;
    this.selectedItemsMoved.next(option);
    this.massCheckControl.setValue(false);
  }

  onDelete() {
    this.moveDropdownVisible = false;
    this.selectedItemsDeleted.next(undefined);
    this.massCheckControl.setValue(false);
  }

  @HostListener('document:click', ['$event'])
  clickout(event: { target: any; }) {
    if (this.dropdownMenu && !this.dropdownMenu.nativeElement.contains(event.target)) {
      this.moveDropdownVisible = false;
    }
  }

  toggleDropdown(event: MouseEvent) {
    event.stopPropagation(); // Prevent click event from propagating to the document
    this.moveDropdownVisible = !this.moveDropdownVisible;
  }

  protected readonly event = event;
}
