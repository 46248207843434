import {AfterViewInit, Injectable, OnDestroy, OnInit} from '@angular/core';
import {BehaviorSubject, debounceTime, fromEvent, Subject, Subscription} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ThemingService implements OnInit, OnDestroy, AfterViewInit {


  public themes = {
    blue: {
      primary: '70,96,234',
      dark: '58, 61, 63',
    },
    purple: {
      primary: '108,58,214',
      dark: '106,99,122',
    },
    'spring-green': {
      primary: '98,154,26',
      dark: '60,63,58',
    },
    'sea-green': {
      primary: '26,127,109',
      dark: '58,63,62',
    },
    teal: {
      primary: '6,120,155',
      dark: '58,63,63',
    },
    forest: {
      primary: '39,107,33',
      dark: '58,63,59',
    },
    navy: {
      primary: '35,85,131',
      dark: '58,63,63',
    },
    orange: {
      primary: '193,74,23',
      dark: '63,60,58',
    },
    fuscia: {
      primary: '179,34,215',
      dark: '122,99,113',
    },
    'money-life': {
      primary: '125, 106, 145',
      dark: '92, 48, 138'
    },
    'ernst-financial': {
      primary: '33,45,31',
      dark: '33,45,31'
    },
    'lwilliam': {
      primary: '0,118,209',
      dark: '0,118,209'
    },
    'womenOfColor': {
      primary: '236,14,114',
      dark: '212,13,103'
    },
    avilia: {
      primary: '7, 50, 44',
      dark: '7, 50, 44'
    }
  };

  public colorMap = {
    green: '#0b7b2a',
    red: '#d20d2e',
    faded: '#636c7a'
  }

  // public COLORS: {
  //   PRIMARY: string;
  //   SECONDARY: string;
  //   SUCCESS: string;
  //   INFO: string;
  //   WARNING: string;
  //   DANGER: string;
  //   LIGHT: string;
  //   DARK: string;
  //   THANE: string;
  // } = {
  //   PRIMARY   : 'primary',
  //   SECONDARY : 'secondary',
  //   SUCCESS   : 'success',
  //   INFO      : 'info',
  //   WARNING   : 'warning',
  //   DANGER    : 'danger',
  //   LIGHT     : 'light',
  //   DARK      : 'dark',
  //   THANE     : 'thane',
  // };

  selectedTheme: BehaviorSubject<{primary: string, dark: string}> = new BehaviorSubject<{primary: string; dark: string}>(this.themes.blue)
  themeNames = [
    'blue',
    'purple',
    'spring-green',
    'sea-green',
    'teal',
    'forest',
    'navy',
    'orange',
    'fuscia',
    'money-life'
  ];

  screenSize: BehaviorSubject<'sm' | 'md' | 'lg' | 'xl' | 'xxl'> = new BehaviorSubject<"sm" | "md" | "lg" | "xl" | "xxl">('sm');
  private resizeSubscription: Subscription | null = null;

  constructor() {
    this.resizeSubscription = fromEvent(window, 'resize')
      .pipe(debounceTime(250)) // debounce it to prevent high frequency updates
      .subscribe(() => {
        this.screenSize.next(this.getScreenSize())
      })
  }

  ngAfterViewInit(): void {
    this.screenSize.next(this.getScreenSize());
  }

  ngOnInit(): void {
    }

  ngOnDestroy(): void {
    this.resizeSubscription?.unsubscribe();
    }

  getScreenSize(): 'sm' | 'md' | 'lg' | 'xl' | 'xxl' {
    const width = window.innerWidth;

    if (width <= 420) return 'sm';
    else if (width > 420 && width <= 768) return 'md';
    else if (width > 768 && width <= 1024) return 'lg';
    else if (width > 1024 && width <= 1440) return 'xl';
    else return 'xxl';
  }

  getPrimaryRGB() {
    return this.selectedTheme.value.primary;
  }

  setTheme(theme: string) {
    // @ts-ignore
    this.selectedTheme.next(this.themes[theme]);
    const html = document.documentElement;
    const attr = 'data-theme';
    html.setAttribute(attr, theme);
  }

  randomizeTheme() {

    const html = document.documentElement;
    const attr = 'data-theme';
    let random = Math.floor(Math.random() * this.themeNames.length);
    let randomizedColor = this.themeNames[random];

    this.setTheme(randomizedColor);
    html.setAttribute(attr, randomizedColor);

    // const themeKeys = Object.keys(this.themes); // Get an array of theme keys
    // const randomIndex = Math.floor(Math.random() * themeKeys.length); // Generate a random index
    // const randomThemeKey = themeKeys[randomIndex]; // Get the theme key at the random index
    // // @ts-ignore
    // const randomTheme = this.themes[randomThemeKey]; // Get the theme object using the theme key
    //
    // this.selectedTheme.next(randomTheme); // Set the random theme as the selected theme
  }
}
