import { Injectable } from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, ActivatedRoute} from '@angular/router';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import {Observable, from, combineLatest, of} from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import {AuthService} from "../services/auth.service";
import {HeaderService} from "../../tandem-core/services/header.service";

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private afAuth: AngularFireAuth, private authService: AuthService, private router: Router, private route: ActivatedRoute, private headerService: HeaderService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return combineLatest([this.afAuth.authState, this.authService.$user]).pipe(
      take(1),
      switchMap(([firebaseUser, userDocument]) => {
        if (firebaseUser && userDocument) {

          if (!userDocument.hasPromptedName) {
            this.router.navigate(['/auth/additional-info']).then(res => this.headerService.resetConfig()); // Adjust the path as needed
          }
          // Check if route requires specific roles
          const requiredRoles = route.data['requiredRole'];
          if (!requiredRoles) {
            // No specific roles required, allow access
            return of(true);
          }

          // If specific roles are required, proceed with the role check
          return from(firebaseUser.getIdTokenResult()).pipe(
            map(idTokenResult => {
              const customClaims = idTokenResult.claims;
              if (typeof requiredRoles === 'string') {
                return customClaims[requiredRoles] === true;
              } else if (Array.isArray(requiredRoles)) {
                return requiredRoles.some(role => customClaims[role] === true);
              }
              return false;
            })
          );
        } else if (firebaseUser && !userDocument) {
          console.log(firebaseUser)
          // Handle the case where there is an authenticated user but no user document
          // Check if 'attemptedPath' is already in the query params
          const currentAttemptedPath = this.router.getCurrentNavigation()?.extractedUrl.queryParams['attemptedPath'];
          if (!currentAttemptedPath) {
            this.router.navigate(['/auth/creating-account'], { queryParams: { attemptedPath: state.url } });
          }
          // const newPath = currentAttemptedPath || state.url;

          // Redirect to creating-account with attemptedPath only if it's not already there
          return from([true]);
        } else {
          const currentPath = this.router.url.split('?')[0];

          console.log(currentPath)
          // Check if the current URL is not '/auth/register'
          if (currentPath !== '/auth/register') {
            // If not, navigate to '/auth/login' while preserving query parameters
            this.router.navigate(['/auth/login'], { queryParamsHandling: 'preserve' });
          } else {
            this.router.navigate(['/auth/register'], { queryParamsHandling: 'preserve' });
          }
          return from([false]);
        }
      }),
      tap(isAllowed => {
        if (!isAllowed) {
          this.router.navigate(['/auth/unauthorized']); // Adjust the path as needed
        }
      })
    );
  }
}
