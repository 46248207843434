<tr class="w-full flex block items-center relative">
  <tandem-form-checkbox *ngIf="hasCheckbox" [control]="isChecked" (checkboxToggled)="onToggle($event)"></tandem-form-checkbox>
  <div
    class="border-[1px] w-full rounded-sm text-14 px-15 py-11 flex items-center justify-between border-dark/20 [&>td]:w-full [&>td:last-child]:text-right [&>td:first-child]:text-left"
  >
    <img
      *ngIf="type === 'draggable'"
      class="cursor-grab active:cursor-grabbing mr-10 hover:opacity-50 duration-250 transition-quad-inout"
      src="../../../../../../assets/icons/drabbagle.png"
      alt="dragging icon"
    />
    <ng-content />
    <div class="relative" *ngIf="hasMeatball">
<!--      <img-->
<!--        *ngIf="hasMeatball"-->
<!--        class="cursor-pointer hover:opacity-50 duration-250 transition-quad-inout h-18 px-10" style="z-index: 10000"-->
<!--        src="../../../../../../assets/icons/meatball.png"-->
<!--        alt="menu icon"-->
<!--        (click)="toggleDropdown($event)"-->
<!--      />-->
      <tandem-icon icon="circle-menu" color="black" (click)="toggleDropdown($event)"></tandem-icon>

      <div #dropdownMenu *ngIf="rowDropdownVisible" class="example-menu" cdkMenu [ngClass]="{'display-none': !rowDropdownVisible}">
        <button (click)="$event.stopPropagation(); onEdit.emit(); toggleDropdown($event)" class="example-menu-item" cdkMenuItem><tandem-icon icon="edit" color="black" class="mr-10"></tandem-icon>EDIT</button>
        <button *ngIf="showMove" (click)="$event.stopPropagation(); onMove.emit(); toggleDropdown($event)" class="example-menu-item" cdkMenuItem><tandem-icon icon="move" color="black" class="mr-10"></tandem-icon>UNCATEGORIZE</button>
        <button (click)="delete($event); toggleDropdown($event)" class="example-menu-item" cdkMenuItem><tandem-icon icon="trash" color="red" class="mr-10"></tandem-icon><span class="text-red">DELETE</span></button>
      </div>
    </div>

    <!-- Dropdown Menu -->
<!--    <div-->
<!--      #dropdownMenu-->
<!--      *ngIf="dropdownVisible"-->
<!--      class="absolute top-full right-0 mt-2 inline-flex px-8 py-16 gap-10 rounded-xs bg-dark/20 z-50 tandem-dropdown shadow-lg"-->
<!--      (click)="$event.stopPropagation()"-->
<!--    >-->

<!--      <button class="flex flex-row items-center gap-6" (click)="delete($event)">-->
<!--        <span class="text-12 font-medium text-red">DELETE</span>-->
<!--      </button>-->
<!--      <div class="w-1 h-21 bg-dark"></div>-->
<!--      <button class="flex flex-row items-center gap-6" (click)="$event.stopPropagation(); onEdit.emit()">-->

<!--        <span class="text-12 font-medium text-dark">EDIT</span>-->
<!--      </button>-->
<!--      <div *ngIf="showMove"  class="w-1 h-21 bg-dark"></div>-->
<!--      <button *ngIf="showMove" class="flex flex-row items-center gap-6" (click)="$event.stopPropagation(); onMove.emit()">-->
<!--        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="17" viewBox="0 0 15 17" fill="none">-->
<!--          <path d="M12.8667 6.44998L10.0333 3.64998L10.9667 2.71665C11.2222 2.46109 11.5362 2.33331 11.9087 2.33331C12.2811 2.33331 12.5949 2.46109 12.85 2.71665L13.7833 3.64998C14.0389 3.90554 14.1722 4.21398 14.1833 4.57531C14.1944 4.93665 14.0722 5.24487 13.8167 5.49998L12.8667 6.44998ZM11.9 7.43331L4.83333 14.5H2V11.6666L9.06667 4.59998L11.9 7.43331Z" fill="#3A3D3F"/>-->
<!--        </svg>-->
<!--        <span class="text-12 font-medium text-dark">MOVE TO UNCATEGORIZED</span>-->
<!--      </button>-->
<!--    </div>-->
  </div>
</tr>
