<div *ngIf="user" class="grid-container xl-max:w-full">
  <div
    class="cols-6 -mt-70 xl:-mt-120  flex flex-row h-134 justify-content-center align-items-center cols-ml-1">
    <img class="profile-img border-4 border-primary rounded-full z-50 bg-background mb-30"
         [src]="user.photoURL ? user.photoURL : 'assets/images/profileForTandem.png'" alt="profile picture">
  </div>
  <div class="flex flex-row mb-15">
    <div class="cols-6 cols-ml-1 justify-center align-center">
      <p>Member since: {{user!.dateCreated.toDate() | date}}</p>
    </div>
  </div>
  <div class="inline-flex flex-row">
    <div class="cols-6 flex flex-col gap-10">
      <div class="cols-6 cols-ml-1 font-extrabold text-28">
        <h2>
          {{user!.firstName + " " + user!.lastName}}
        </h2>
      </div>
      <div class="cols-6 cols-ml-1">
      <p>Email: {{user!.email}}</p>
        </div>
<!--      <div class="cols-6 cols-ml-1 justify-center align-center">-->
<!--        <a class="underline text-primary hover:cursor-pointer" (click)="onLogout()">-->
<!--          Not {{user!.firstName}}? Logout-->
<!--        </a>-->
<!--      </div>-->
      <div class="cols-6 cols-ml-1 justify-start align-center mb-40">
        <span class="inline-flex items-center pr-15"><tandem-icon icon="document" color="black" class="pr-8"></tandem-icon> {{user!.statementsCreated}} statements created</span>
        <span class="inline-flex items-center"><tandem-icon icon="target" color="black" class="pr-8"></tandem-icon>
          {{user!.goalsCompleted}} goals completed</span>
      </div>
      <div class="flex flex-col gap-10 cols-6 cols-ml-1 justify-start" *ngIf="!coach">
        <h2>User Welcome Video</h2>
        <h5>This is where you can configure your custom video that will appear for your users when they are taken to their dashboard for the first time. The video should introduce and explain all important parts of the Tandem Platform, such as the Dashboard, Tracking Tools, and Calculators</h5>
        <iframe [src]="sanitizedVideoURL" class="w-full h-300 rounded-sm" frameborder="0" allow="fullscreen"></iframe>
        <div class="flex flex-row justify-center">
          <tandem-button *ngIf="!editingVideoURL" theme="outline" color="primary" [onClick]="toggleVideoEdit.bind(this)">CHANGE VIDEO URL</tandem-button>
          <ng-container *ngIf="editingVideoURL">
            <div class="flex flex-row items-center gap-10 w-full">
              <tandem-form-input class="w-full" [form]="videoForm" [controlName]="'url'" label="URL" inputId="url" placeholder="Video URL"></tandem-form-input>
              <tandem-button theme="solid" color="red" [onClick]="toggleVideoEdit.bind(this)">CANCEL</tandem-button>
              <tandem-button theme="solid" color="primary">Update</tandem-button>
            </div>
          </ng-container>
        </div>
      </div>
      <div class="cols-6 cols-ml-1 justify-start align-center" *ngIf="coach">
        <p>Have questions? Email your coach at {{coach.firstName}} <a
          class="underline text-primary hover:cursor-pointer">{{coach.email}}</a></p>
      </div>
<!--      <div *tandemAuth="'isCoach'" class="cols-6 cols-ml-1 flex flex-col gap-15">-->
<!--        <h3 class="mb-10">Appearance</h3>-->
<!--        <h4>Themes</h4>-->
<!--        <p>Changes the theme for all of your users</p>-->
<!--        <div class="flex flex-row flex-wrap gap-10 justify-start">-->
<!--          <div class="w-50 h-50 rounded-lg border border-black hover:opacity-70 hover:cursor-pointer" (click)="switchTheme(t)" *ngFor="let t of themes" style="background-color: rgb({{t}})"></div>-->
<!--        </div>-->
<!--      </div>-->
      <tandem-card class="cols-6 cols-ml-1 my-50" theme="primary-faded">
        <div class="flex flex-col gap-10">
          <h3>Need help with something? Let us know below!</h3>
          <p>Let us know what we can do for you. We usually respond within 24 hours.</p>
          <tandem-form-input placeholder="Subject" [inputId]="'subject'" label="Subject" [form]="feedbackForm" [controlName]="'subject'"></tandem-form-input>
          <tandem-form-textarea placeholder="Feedback" [inputId]="'feedback'" label="Feedback" [form]="feedbackForm" [controlName]="'feedback'" [rows]="6"></tandem-form-textarea>
          <div class="flex flex-row justify-end mt-10">
            <tandem-button theme="solid" color="primary" [disabled]="feedbackForm.invalid">SEND</tandem-button>
          </div>
        </div>
      </tandem-card>
    </div>
    <div class="cols-10 cols-ml-2 flex flex-col mb-20">
      <div class="cols-10 font-extrabold text-28">
        <h2>Account Details</h2>
      </div>
      <div class="flex-row inline-flex mb-20">
        <div class="cols-5 justify-start align-center" >
          <tandem-card class="h-full flex flex-col gap-10">
            <ng-container *ngIf="userSubscription; else noSubscription">
              <div class="flex flex-row justify-between mb-5">
                <h4>Membership</h4>
                <div [ngSwitch]="userSubscription.status">
                  <div *ngSwitchCase="'SUCCESS'" class="flex flex-row gap-5 items-center">
                    <h4>Active</h4>
                    <svg class="ml-10" xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                      <circle cx="5" cy="5" r="5" fill="#56E07D"></circle>
                    </svg>
                  </div>
                  <div *ngSwitchCase="'FAILED'" class="flex flex-row gap-5 items-center">
                    <h4>Payment Failed</h4>
                    <!-- Add suitable icon or SVG for 'Failed' status -->
                  </div>
                  <div *ngSwitchCase="'CANCELLED'" class="flex flex-row gap-5 items-center">
                    <h4>Cancelled</h4>
                    <!-- Add suitable icon or SVG for 'Cancelled' status -->
                  </div>
                  <div *ngSwitchCase="'EXPIRED'" class="flex flex-row gap-5 items-center">
                    <h4>Expired</h4>
                    <!-- Add suitable icon or SVG for 'Expired' status -->
                  </div>
                </div>
              </div>
              <div [ngSwitch]="userSubscription.status">
                <h5 *ngSwitchCase="'SUCCESS'">
                  {{userSubscription.amount / 100 | currency}}/{{userSubscription.billingInterval}} (renews {{userSubscription.nextBillingDate.toDate() | date}})
                </h5>
                <h5 *ngSwitchCase="'CANCELLED'">
                  You will lose access on {{userSubscription.endDate.toDate() | date}}. <tandem-button class="w-180" theme="outline" color="primary" [onClick]="openStripe.bind(this)">Renew</tandem-button>
                </h5>
                <h5 *ngSwitchCase="'EXPIRED'">
                  Jump back into your subscription. <tandem-button class="w-180" theme="outline" color="primary" [onClick]="openStripe.bind(this)">Reactivate</tandem-button>
                </h5>
                <h5 *ngSwitchCase="'FAILED'">
                  Your last payment failed. <tandem-button class="w-180" theme="outline" color="red" [onClick]="openStripe.bind(this)">Retry Payment</tandem-button>
                </h5>
              </div>
              <div *ngIf="userSubscription.status === 'SUCCESS'" class="inline-flex gap-10 mt-9 w-fit">
                <tandem-button class="w-180" theme="outline" color="red" [onClick]="cancelSubscription.bind(this)">CANCEL SUBSCRIPTION</tandem-button>
              </div>
            </ng-container>
            <ng-template #noSubscription>
              <div class="flex flex-row justify-between mb-5">
                <h4>Membership</h4>
                <div class="flex flex-row gap-5 items-center">
                  <h4>No Access</h4>
                  <svg class="ml-10" xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                    <circle cx="5" cy="5" r="5" fill="#636C7A"></circle>
                  </svg>
                </div>
              </div>
              <p class="mt-30">Looks like you haven't started a subscription yet. Let's change that!</p>
              <div class="inline-flex gap-10 mt-9 w-fit">
                <tandem-button class="w-180" theme="outline" color="primary" [onClick]="openStripe.bind(this)">ACTIVATE ACCOUNT</tandem-button>
              </div>
            </ng-template>
          </tandem-card>
        </div>
      </div>
      <ng-container *ngIf="user.coachId">
        <div class="cols-10 font-extrabold text-28 my-20">
          <h2>
            Tracking Tool Visibility
          </h2>
        </div>
        <div class="cols-9 mb-20">
          <p>Set which tracking tools can send data to your coach.<br>
            This can be helpful in meetings where both of you can look at data simultaneously.
          </p>
          <div class="inline-flex cols-3 mt-10 gap-10">
            <tandem-card routerLink="/financial-positions" class=" hover: cursor-pointer">
              <div class="inline-flex items-center  cols-3 gap-10 justify-center align-middle" style="height: 25px">
                <tandem-icon icon="financialPosition"></tandem-icon>
                <h2 class="text-12 mt-2">Financial Positions</h2>
                <tandem-icon icon="eyeOpen" color="black"></tandem-icon>
              </div>
            </tandem-card>
            <tandem-card routerLink="/spending-plans" class=" hover: cursor-pointer">
              <div class="inline-flex items-center  cols-2 gap-10 justify-center align-middle" style="height: 25px">
                <tandem-icon icon="cashFlow"></tandem-icon>
                <h2 class="text-12">Cash Flow</h2>
                <tandem-icon icon="eyeClosed" color="black"></tandem-icon>
              </div>
            </tandem-card>
            <tandem-card routerLink="/spending-plans" class=" hover: cursor-pointer">
              <div class="inline-flex items-center gap-10 cols-3 justify-center align-middle" style="height: 25px">
                <tandem-icon icon="spendingPlan"></tandem-icon>
                <h2 class="text-12 mt-2">Prioritized Spending Plan</h2>
                <tandem-icon icon="eyeClosed" color="black"></tandem-icon>
              </div>
            </tandem-card>
          </div>
        </div>
      </ng-container>

<!--      <div class="cols-10 font-extrabold text-28">-->
<!--        <h2>Linked Bank Accounts</h2>-->
<!--      </div>-->
<!--      <div class="flex-row inline-flex">-->
<!--        <div class="cols-5 justify-start align-center" >-->
<!--          <tandem-card class="h-full flex-row cols-9">-->
<!--            <div class="w-full h-full cols-9">-->
<!--              <tandem-plaid></tandem-plaid>-->
<!--            </div>-->
<!--          </tandem-card>-->
<!--        </div>-->
<!--      </div>-->

      <ng-container *tandemAuth="'isCoach'">
        <div class="cols-10 font-extrabold text-28 mt-50">
          <h2>
            Customize Tracking Tools
          </h2>
        </div>
        <div class="cols-9 ">
          <p>Customize exactly what default configuration your users have for their tracking tools<br>
            This is useful for giving your users a solid starting point to track their finances
          </p>
          <div class="inline-flex cols-3 mt-10 gap-10">
            <tandem-card routerLink="/coaching/customize/financial-position" class="hover: cursor-pointer">
              <div class="inline-flex items-center  cols-3 gap-10 justify-center align-middle" style="height: 25px">
                <tandem-icon icon="financialPosition"></tandem-icon>
                <h2 class="text-12 mt-2">Financial Positions</h2>
              </div>
            </tandem-card>
            <tandem-card routerLink="/coaching/customize/statement" class="hover: cursor-pointer">
              <div class="inline-flex items-center cols-4 gap-10 justify-center align-middle" style="height: 25px">
                <tandem-icon icon="cashFlow"></tandem-icon>
                <h2 class="text-12">Cash Flows and Spending Plans</h2>
                <tandem-icon icon="spendingPlan"></tandem-icon>
              </div>
            </tandem-card>
<!--            <tandem-card routerLink="/coaching/customize/spending-plan" class="hover: cursor-pointer">-->
<!--              <div class="inline-flex items-center gap-10 cols-3 justify-center align-middle" style="height: 25px">-->
<!--                <tandem-icon icon="spendingPlan"></tandem-icon>-->
<!--                <h2 class="text-12 mt-2">Prioritized Spending Plan</h2>-->
<!--              </div>-->
<!--            </tandem-card>-->
          </div>
        </div>
        <tandem-card theme="red-faded" class="mt-40">
          <div class="flex flex-col gap-10">
            <h3>Want to delete or deactivate your account?</h3>
            <p>A quick 15-minute call helps our team make sure your users' information and account functionality is taken into consideration.</p>
            <tandem-button class="w-fit" theme="solid" color="red">SCHEDULE TRANSITION CALL</tandem-button>
          </div>
        </tandem-card>
      </ng-container>
    </div>
  </div>
</div>
