import {Component, OnInit} from '@angular/core';
import {AuthService} from "../../services/auth.service";
import {HeaderService} from "../../../tandem-core/services/header.service";
import {TandemUser} from "../../models/tandem-user";
import {DialogService} from "../../../tandem-core/services/dialog.service";
import {ThemingService} from "../../../tandem-core/services/theming.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {LinkedInstitution} from "../../../plaid/models/linked-institution";
import {AngularFireAuth} from "@angular/fire/compat/auth";
import {environment} from "../../../../../environments/environment";
import {UserService} from "../../user.service";
import {UserSubscription} from "../../models/user-subscription";
import {PaymentService} from "../../../stripe/services/payment-service";
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";


@Component({
  selector: 'tandem-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  user: TandemUser | null = null;
  coach: TandemUser | null = null;

  themes: string[] = [];

  feedbackForm: FormGroup = new FormGroup<any>({});

  private SERVER_PATH = environment.functionsPath;
  public userSubscription: UserSubscription | undefined;

  sanitizedVideoURL: SafeResourceUrl | undefined;
  editingVideoURL = false;
  videoForm: FormGroup = new FormGroup<any>({});

  constructor(private auth: AuthService,
              private afAuth: AngularFireAuth,
              private sanitizer: DomSanitizer,
              private fb: FormBuilder,
              private headerService: HeaderService,
              private dialogService: DialogService,
              private themingService: ThemingService,
              private paymentService: PaymentService,
              private userService: UserService,
              private http: HttpClient) {
  }

  ngOnInit(): void {
    this.feedbackForm = this.fb.group({
      subject: [null, Validators.required],
      feedback: [null, Validators.required]
    })
    this.videoForm = this.fb.group({
      url: [null, Validators.required]
    });
    this.headerService.setConfig({title: 'Profile'});
    this.auth.$user.subscribe(user => {
      this.user = user;
      if (this.user) {
        if (this.user.coachId) {
          this.auth.getUser(this.user.coachId).subscribe((coach: TandemUser) => {
            this.coach = coach;
          })
        }
        console.log(this.user.uid)
        this.userService.getSubscriptionInformationForUser(this.user.uid).subscribe(user => {
          console.log(user)
          this.userSubscription = user;
        })
        const videoUrl = environment.defaultWelcomeURL;
        this.sanitizedVideoURL = this.sanitizer.bypassSecurityTrustResourceUrl(videoUrl);
        this.videoForm.get('url')?.setValue(environment.defaultWelcomeURL);
      }
    });
    // @ts-ignore
    this.themes = Object.keys(this.themingService.themes).map((k: string) => this.themingService.themes[k]).map((theme: {primary: string, secondary: string}) => theme.primary);
  }

  openStripe() {
    this.paymentService.openStripe();
  }

  cancelSubscription() {
    this.dialogService.openConfirmDialog('Cancel Subscription', 'Are you sure you want to cancel your subscription?', 'Yes, cancel', 'No, go back').afterClosed().subscribe(confirmed => {
      if (confirmed) {
        this.dialogService.openLoadingDialog('Cancelling Subscription', 'Please wait, we\'re cancelling your subscription.')
        this.paymentService.cancelSubscription().then(res => {
          const endDate: Date = res.endDate;
          this.dialogService.openMessageDialog('Successfully Cancelled',
            `We're sad to see you go, but your subscription has successfully been cancelled. You'll still have access until ${endDate.toDateString() || 'the end of the current billing period'}.`);
        });
      }
    })
  }

  changeEmail() {
    this.auth.changeEmail()
  }

  resetPassword() {
    this.auth.changePassword()
  }

  onLogout(): void {
    this.auth.onLogout();
  }

  toggleVideoEdit() {
    this.editingVideoURL = !this.editingVideoURL;
  }

  switchTheme(t: string) {
    this.dialogService.openConfirmDialog('Change Theme', 'Are you sure you want to change your theme? ' +
      'This will update the appearance of the Tandem application for you as well as all of your users.')
      .afterClosed()
      .subscribe(confirmed => {
        this.updateUserTheme(this.themingService.themeNames[this.themes.indexOf(t)]).then(res => {
          console.log('Successfully updated your theme for all users!')
        })
      })
  }

  public async updateUserTheme(theme: string) {
    const idToken = await this.getIdToken();
    if (idToken) {
      const headers = new HttpHeaders().set('Authorization', `Bearer ${idToken}`);
      const params = new HttpParams().set('newTheme', theme);
      return this.http.get<LinkedInstitution[]>(`${this.SERVER_PATH}/updateUserTheme`,{ headers, params }).toPromise();
    }
    // Handle error or throw an error if no user or ID token
    throw new Error('User is not currently signed in.');
  }

  private async getIdToken() {
    const user = await this.afAuth.currentUser;
    if (user) {
      return user.getIdToken();
    }
    return null;
  }
}
