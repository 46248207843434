import { Component, Input, OnInit } from '@angular/core';
import {ThemeType} from "../../models/theme-type";
import {IconType} from "../icons/icon-type";

@Component({
  selector: 'tandem-button',
  templateUrl: './button.component.html',
})
export class ButtonComponent implements OnInit {
  buttonVariant = {
    pill: 'rounded-sm font-medium uppercase text-12-caps py-5 px-8',
    circle:
      'text-12-caps uppercase text-dark flex items-center justify-center font-medium',
    icon: 'text-12-caps font-medium uppercase text-dark underline-offset-2',
  };

  buttonTheme = {
    solid: 'bg-primary text-background',
    outline: 'bg-background text-primary',
    none: ''
    // outline: 'bg-background border-[1px] border-primary text-primary ',
  };

  iconClass = 'w-200';

  @Input() variant: 'circle' | 'pill' | 'icon' = 'pill';
  @Input() type?: 'submit' | 'button' | undefined = 'button';
  @Input() disabled?: boolean = false;
  @Input() theme?: 'solid' | 'outline' | 'none' | undefined = "outline";
  @Input() iconDisplay: 'inline' | 'separate' = 'separate';
  @Input() icon?: IconType | undefined;
  @Input() name?: string;
  @Input() label: string | undefined = undefined;
  @Input() onClick: () => void = () => {};
  @Input() routerLink: string | undefined;

  @Input() color: 'primary' | 'secondary' | 'faded' | 'red' | 'transparent' = 'primary';

  constructor() {}

  ngOnInit(): void {
    if (this.theme === 'outline') {
      this.buttonTheme[this.theme] += ` border border-${this.color} text-${this.color}`;
    } else if (this.theme === 'solid') {
      this.buttonTheme[this.theme] += ` bg-${this.color}`;
    }
  }
}
