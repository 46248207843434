import {Injectable, Injector, Renderer2, RendererFactory2, Type} from '@angular/core';

import {BehaviorSubject} from "rxjs";
import {Overlay, OverlayConfig, OverlayRef} from "@angular/cdk/overlay";
import {ComponentPortal, PortalInjector} from "@angular/cdk/portal";
import {ModalRef} from "../models/modal-ref";
import {ConfirmDialogComponent} from "../components/confirm-dialog/confirm-dialog.component";
import {LoadingDialogComponent} from "../components/loading-dialog/loading-dialog.component";

export interface AlertState {
  position: 'top' | 'bottom';
  title: string;
  content: string;
  dismissTime: number
}

@Injectable({
  providedIn: 'root',
})
export class DialogService {

  $alertState: BehaviorSubject<AlertState | null> = new BehaviorSubject<AlertState | null>(null)

  private renderer: Renderer2;

  constructor(private overlay: Overlay, private injector: Injector, rendererFactory: RendererFactory2) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  openModal<T>(component: Type<T>, data?: any, config?: OverlayConfig): ModalRef {
    this.renderer.addClass(document.body, 'modal-open');
    this.renderer.setStyle(document.body, 'pointer-events', 'none');
    let overlayConfig = config || new OverlayConfig({

    });
    overlayConfig = {...{hasBackdrop: true,
        backdropClass: 'cdk-overlay-shaded-backdrop',
        panelClass: 'my-modal-class',

        positionStrategy: this.overlay.position()  // add this line
          .global()
          .centerHorizontally()
          .centerVertically()}}

    const overlayRef = this.overlay.create(overlayConfig);
    overlayRef.backdropClick().subscribe(ev => {
      ev.stopImmediatePropagation();
    })
    const modalRef = new ModalRef(overlayRef, data);

    document.addEventListener('keydown', (event: KeyboardEvent) => {
      if(event.key === 'Escape' || event.keyCode === 27) {
        modalRef.close();
      }
    })

    const injector = this.createModalInjector(modalRef);
    const portal = new ComponentPortal(component, null, injector);

    overlayRef.attach(portal);

    this.focusFirstInputOrButton(overlayRef);

    const overlayPane = overlayRef.overlayElement;
    this.renderer.addClass(overlayPane, 'slide-up');

    modalRef.afterClosed().subscribe(value => {
      this.renderer.removeClass(document.body, 'modal-open');
      this.renderer.removeStyle(document.body, 'pointer-events');
    });

    return modalRef;
  }

  public showAlert(alertState: AlertState) {
    this.$alertState.next(alertState);
  }

  public openConfirmDialog(title: string,
                           message: string,
                           confirmText: string = 'Confirm',
                           cancelText: string = 'Cancel',
                           hideConfirm: boolean = false,
                           hideCancel: boolean = false,
                           confirmIsPrimary: boolean = false): ModalRef {
    return this.openModal(ConfirmDialogComponent, {title: title, message: message, confirmText: confirmText, cancelText: cancelText, hideConfirm: hideConfirm, hideCancel: hideCancel, confirmIsPrimary: confirmIsPrimary})
  }

  public openMessageDialog(title: string, message: string) {
    return this.openConfirmDialog(title, message, '', 'Close', true, false);
  }

  public openLoadingDialog(title: string,
                           message: string): ModalRef {
    return this.openModal(LoadingDialogComponent, {title: title, message: message})
  }

  private createModalInjector(modalRef: ModalRef): PortalInjector {
    const injectionTokens = new WeakMap();

    injectionTokens.set(ModalRef, modalRef);

    return new PortalInjector(this.injector, injectionTokens);
  }

  private focusFirstInputOrButton(overlayRef: OverlayRef): void {
    setTimeout(() => {
      const overlayElement = overlayRef.overlayElement;
      const firstInputOrButton = overlayElement.querySelector('input, button');
      if (firstInputOrButton) {
        (firstInputOrButton as HTMLElement).focus();
      }
    });
  }
}
