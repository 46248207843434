import {Component, Inject, OnInit} from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";
import {ModalRef} from "../../models/modal-ref";
import {AuthService} from "../../../auth/services/auth.service";
import {environment} from "../../../../../environments/environment";

@Component({
  selector: 'tandem-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss']
})
export class VideoPlayerComponent implements OnInit {

  videoURL: string = '';
  public sanitizedVideoUrl: SafeResourceUrl | null = null;


  constructor(private sanitizer: DomSanitizer,
              private auth: AuthService,
  @Inject(ModalRef) public modalRef: ModalRef) {}


  ngOnInit(): void {
    this.auth.$user.subscribe(user => {
      if (user?.coachId) {
        const videoUrl = environment.defaultWelcomeURL;
        this.sanitizedVideoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(videoUrl);
      } else {
        const videoUrl = environment.coachVideoURL;
        this.sanitizedVideoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(videoUrl);
      }
    })

  }

  toggleFullScreen() {
    const videoElement = document.getElementById('yourVideoElementId'); // Your video or container element

    if (!document.fullscreenElement) {
      if (videoElement?.requestFullscreen) {
        videoElement.requestFullscreen();
      } // Add else ifs for webkitRequestFullscreen, mozRequestFullScreen, msRequestFullscreen for broader browser support
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } // Add else ifs for webkitExitFullscreen, mozCancelFullScreen, msExitFullscreen for broader browser support
    }
  }

  close() {
    this.modalRef.close();
  }
}
