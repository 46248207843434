<form [formGroup]="statementItemForm" class="flex flex-col w-full gap-20" (ngSubmit)="onFormSubmit()">
  <h2 class="text-center">{{inputTitle}}</h2>
  <tandem-form-input class="w-full"
                   [form]="statementItemForm"
                   [label]="'Name'"
                   [inputId]="'name'"
                   [controlName]="'name'"
                   [placeholder]="'Name'"></tandem-form-input>
  <tandem-form-input class="w-full"
                   [form]="statementItemForm"
                   [label]="'Value'"
                   [inputId]="'value'"
                   [controlName]="'value'"
                   [placeholder]="'Value'"
                   [inputType]="'number'"></tandem-form-input>
  <tandem-form-input *ngIf="requireDate" class="w-full"
                   [form]="statementItemForm"
                   [label]="'Date'"
                   [inputId]="'date'"
                   [controlName]="'date'"
                   [placeholder]="'Date'"
                   [inputType]="'date'"></tandem-form-input>
  <div class="flex flex-row w-full justify-center gap-15 mt-10">
    <tandem-button theme="outline" [onClick]="onCancel.bind(this)">Cancel</tandem-button>
    <tandem-button theme="solid" type="submit" [onClick]="onSubmit.bind(this)" [disabled]="statementItemForm.invalid">Save</tandem-button>
  </div>
</form>
