<div class="w-full flex flex-col justify-center items-center" style="height: 100vh">
  <div class="relative border-2 border-primary rounded-sm flex flex-col items-center gap-30 w-10/12 md:w-1/2">
    <!-- Heading Wrapper -->
    <div class="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-background px-20">
      <h2>Verify Email</h2>
    </div>
    <!-- Content -->
    <div class="w-3/4 p-20 md:p-60 flex flex-col gap-20 md:gap-60">
      <h4>Thank you for registering with Tandem Finance! We're excited to have you as part of our community, and we hope you're excited to be here!
        To complete your registration and ensure the security of your account, we've sent a verification link to the email address you provided during sign-up.</h4>
      <h4>Didn't receive an email? </h4>
      <div class="flex flex-row justify-center gap-15">
        <tandem-button class="inline-block" theme="outline" color="primary" [onClick]="resendVerification.bind(this)">Resend verification</tandem-button>
<!--        <tandem-button class="inline-block" theme="solid" color="primary" [onClick]="refresh.bind(this)">Refresh</tandem-button>-->
      </div>
      <div class="flex flex-row justify-center gap-15">
        <h5>Not {{user?.email}}?</h5>
        <tandem-button theme="solid" color="primary" (click)="logout()">Logout</tandem-button>
      </div>
      <h4 *ngIf="resent">Successfully sent a verification link to {{user?.email}}. Please check your inbox again.</h4>
    </div>
  </div>
</div>


