import {Component, OnInit} from '@angular/core';
import {AuthService} from "../../services/auth.service";
import {ActivatedRoute} from "@angular/router";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";

@Component({
  selector: 'tandem-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.scss']
})
export class VerifyEmailComponent implements OnInit {

  isLoaded = false;
  errors?: string;
  mode: 'verifyEmail' | 'resetPassword' | string | null = null;
  oobCode: string | null = null;
  newPassForm: FormGroup = new FormGroup<any>({});

  constructor(private auth: AuthService,
              private route: ActivatedRoute,
              private fb: FormBuilder) {
    this.mode = this.route.snapshot.queryParamMap.get('mode');
    this.oobCode = this.route.snapshot.queryParamMap.get('oobCode');
  }

  ngOnInit(): void {
    if (this.mode === 'verifyEmail') {
      // const userId = this.route.snapshot.queryParamMap.get('userId');
      if (this.oobCode) {
        this.auth.verifyEmail(this.oobCode).then(res => {
          this.isLoaded = true;
        }).catch(err => {
          this.errors = err;
          this.isLoaded = false;
        });
      }
    } else if (this.mode === 'resetPassword') {
      this.newPassForm = this.fb.group({
        newPassword: [null, Validators.required]
      });
    }
  }

  public saveNewPassword() {
    if (this.oobCode) {
      this.auth.resetPassword(this.oobCode, this.newPassForm.get('newPassword')?.value).then(res => {
        this.isLoaded = true;
      }).catch(err => {
        let errorMessage = '';
        switch (err.code) {
          case 'auth/expired-action-code':
            errorMessage = "The password reset code has expired. Please request a new code to reset your password.";
            break;

          case 'auth/invalid-action-code':
            errorMessage = "The password reset code you entered is invalid or has already been used. Please check the code and try again, or request a new code if necessary.";
            break;

          case 'auth/user-disabled':
            errorMessage = "The user account associated with this request has been disabled. Please contact support for further assistance.";
            break;

          case 'auth/user-not-found':
            errorMessage = "We couldn't find a user for the provided reset code. It's possible the user account was deleted after the code was issued. Please verify your information or sign up for a new account.";
            break;

          case 'auth/weak-password':
            errorMessage = "Looks like you need a stronger password. Make sure your password has at least one capital letter, one special symbol, and is a minimum of 8 characters long.";
            break;

          // Add a default case for unhandled error codes
          default:
            errorMessage = "An unexpected error occurred. Please try again.";
            break;
        }
        this.errors = errorMessage
        // this.dialogService.openMessageDialog('Unexpected Error', errorMessage);
        this.isLoaded = true;
      });
    } else {
      this.errors = "The link you used is invalid or has already been used. Please check the link and try again, or request a new link if necessary.";
      this.isLoaded = true;
    }
  }
}
