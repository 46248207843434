<ng-container *ngIf="isLoaded; else loadingTemplate">
  <ng-content></ng-content>
</ng-container>

<ng-template #loadingTemplate>
  <div class="flex items-center justify-center h-full">
    <div class="spinner">
      <div class="spinner-dot"></div>
      <div class="spinner-dot"></div>
      <div class="spinner-dot"></div>
    </div>
  </div>
</ng-template>
