import {Component, OnInit} from '@angular/core';
import {AuthService} from "../../services/auth.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Subscription} from "rxjs";

@Component({
  selector: 'tandem-creating-account',
  templateUrl: './creating-account.component.html',
  styleUrls: ['./creating-account.component.scss']
})
export class CreatingAccountComponent implements OnInit {

  userDocSubscription?: Subscription;
  attemptedPath: string | null = null;

  constructor(private auth: AuthService,
              private route: ActivatedRoute,
              private router: Router) {
    this.attemptedPath = this.route.snapshot.queryParamMap.get('attemptedPath');
  }

  ngOnInit(): void {
    this.auth.getCurrentUser().then(afAuthUser => {
      if (afAuthUser) {
        this.userDocSubscription = this.auth.$user.subscribe(user => {
          if (user) {
            if (this.attemptedPath) {
              const urlTree = this.router.parseUrl(this.attemptedPath);
              this.router.navigate(urlTree.root.children['primary'].segments.map(it => it.path), { queryParams: urlTree.queryParams });
            } else {
              this.router.navigate(['/dashboard']);
            }
            this.userDocSubscription?.unsubscribe();
          } else {
            // Handle the case where the user document is still not available
          }
        })
      }
    })


  }

}
