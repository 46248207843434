<div class="w-full flex flex-col justify-center items-center" style="height: 100vh">
  <div class="relative border-2 border-primary rounded-sm flex flex-col items-center gap-30 w-10/12 md:w-1/2">
      <div class="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-background px-20">
        <h2>Finishing Up</h2>
      </div>
    <div style="min-height: 300px" class="flex flex-col justify-center items-center gap-30">
      <h4 (click)="isLoaded = !isLoaded" *ngIf="!isLoaded">Good news, we've processed your payment! One moment while we activate your subscription.</h4>
      <tandem-loading-container [isLoaded]="isLoaded">
        <h4 *ngIf="errorMessage" class="text-red">{{errorMessage}}</h4>
        <h4 *ngIf="!errorMessage">Success! <a [routerLink]="'/dashboard'">Go to Dashboard</a></h4>
      </tandem-loading-container>
    </div>
  </div>
</div>
