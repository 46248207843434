<!--<div class="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">-->
<!--  <div class="modal-content bg-white p-4 rounded-lg shadow-lg relative">-->
<!--    <iframe src="https://www.youtube.com/embed/dQw4w9WgXcQ" frameborder="0" allowfullscreen></iframe>-->
<!--    <button class="absolute top-0 right-0 m-2">Close</button>-->
<!--  </div>-->
<!--</div>-->
<iframe [src]="sanitizedVideoUrl" style="width: 50vw; height: 50vh;" frameborder="0" allowfullscreen></iframe>
<div class="flex flex-row justify-center pt-40">
  <tandem-button color="primary" theme="outline" [onClick]="close.bind(this)">Close</tandem-button>
</div>
