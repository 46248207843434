<ng-container *ngIf="!!control">
  <div class="inline-flex flex-col items-start gap-5 w-full">
    <p class="w-full">{{customLabel ? customLabel : label}}</p>
    <textarea
      [formControl]="control"
      [id]="inputId"
      [placeholder]="placeholder"
      [rows]="rows"
      [cols]="cols"
      [disabled]="isDisabled"
      class="flex px-10 py-15 gap-8 border border-dark/20 rounded-sm w-full"
    ></textarea>
    <div *ngIf="control.invalid && (control.dirty || control.touched) && !controlBlurred" class="text-12 text-red">
      <div *ngIf="control.hasError('required')">{{label}} is required</div>
      <div *ngIf="control.hasError('minLength')">{{label}} doesn't have enough characters</div>
      <div *ngIf="control.hasError('maxLength')">{{label}} has too many characters</div>
    </div>
  </div>
</ng-container>
