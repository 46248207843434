import {Component, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup} from "@angular/forms";

@Component({
  selector: 'tandem-form-textarea',
  templateUrl: './form-textarea.component.html',
  styleUrls: ['./form-textarea.component.scss']
})
export class FormTextareaComponent implements OnInit {
  @Input() rows: number = 10;
  @Input() cols: number = 25;
  @Input() label: string = '';
  @Input() customLabel?: string;
  @Input() form: FormGroup = new FormGroup({});
  @Input() inputId: string = '';
  @Input() placeholder = '';
  @Input() isDisabled = false;
  @Input() controlName: string = '';

  control: FormControl = new FormControl();
  controlBlurred = false;

  constructor() { }

  ngOnInit() {
    this.control = this.form.get(this.controlName) as FormControl;
  }

}
