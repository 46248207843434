<div class="w-full flex flex-col justify-center items-center" style="height: 100vh">
  <div class="relative border-2 border-primary rounded-sm flex flex-col items-center gap-30 w-10/12 md:w-1/2">
    <!-- Heading Wrapper -->
    <div class="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-background px-20">
      <h2>Additional Info Needed</h2>
    </div>
    <!-- Content -->
    <div class="w-3/4 p-20 md:p-60 flex flex-col gap-20 md:gap-60 items-center">
      <h4 class="w-2/3 text-center">We just need a bit more information about you in order to provide you with the best experience. Please fill out the form below.</h4>
      <div class="w-1/2 flex flex-col justify-center gap-15">
        <tandem-form-input [form]="informationForm"
                           [label]="'First Name'"
                           [controlName]="'firstName'"
                           [inputId]="'firstName'"
                           [placeholder]="'First Name'"></tandem-form-input>
        <tandem-form-input [form]="informationForm"
                           [label]="'Last Name'"
                           [controlName]="'lastName'"
                           [inputId]="'lastName'"
                           [placeholder]="'Last Name'"></tandem-form-input>
        <tandem-button class="inline-block" theme="solid" color="primary" [onClick]="submit.bind(this)">Submit</tandem-button>
      </div>
    </div>
  </div>
</div>


