import {Component, Input, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {FormOption} from "../form-option";
import {Subscription} from "rxjs";

@Component({
  selector: 'tandem-form-select',
  templateUrl: './form-select.component.html',
  styleUrls: ['./form-select.component.scss']
})
export class FormSelectComponent implements OnInit {

  @Input() label: string = '';
  @Input() form: FormGroup | AbstractControl = new FormGroup({});
  @Input() inputId: string = '';
  @Input() controlName: string = '';
  @Input() options: FormOption[] = [];

  control: FormControl = new FormControl();
  controlValueChangesSubscription: Subscription = new Subscription();

  displayOptions: string[] = []
  displayControl = new FormControl({});

  constructor(private fb: FormBuilder) { }

  ngOnInit() {
    // Populate displayOptions with labels from the options
    this.displayOptions = this.options.map(o => o.label);
    this.control = this.form.get(this.controlName) as FormControl;
    this.displayControl = new FormControl(this.control.value);
    if (this.control.hasValidator(Validators.required)) {
      this.displayControl.setValidators([Validators.required]);
    }
    // Update the real form control value when the internal control value changes
    this.controlValueChangesSubscription = this.displayControl.valueChanges.subscribe(selectedOption => {
      const matchedOption = this.options.find(option => option.label === selectedOption);
      this.control.setValue(matchedOption?.value);
    });
    if (this.control.disabled) {
      this.displayControl.disable();
    } else {
      this.displayControl.enable()
    }
    this.control.statusChanges.subscribe(status => {
      console.log(status)
      if (this.control.disabled && this.displayControl.enabled) {
        this.displayControl.disable();
      } else if (this.control.enabled && this.displayControl.disabled) {
        this.displayControl.enable();
      }
    })
  }

  compareFn(option1: any, option2: any) {
    if (typeof option1 === 'number' && typeof option2 === 'string') {
      option2 = Number(option2);
    } else if (typeof option2 === 'number' && typeof option1 === 'string') {
      option1 = Number(option1);
    }
    return option1 === option2;
  }
}


