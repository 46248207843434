<div class="w-full flex flex-col justify-center items-center" style="height: 100vh">
  <div class="relative border-2 border-primary rounded-sm flex flex-col items-center gap-30 w-10/12 md:w-1/2">
    <ng-container *ngIf="mode === 'verifyEmail'">
      <tandem-loading-container [isLoaded]="isLoaded">
        <div class="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-background px-20">
          <h2>{{errors ? 'An Error Occurred' : 'Successfully Verified Email'}}</h2>
        </div>
        <div class="w-3/4 p-20 md:p-60 flex flex-col gap-20 md:gap-60">
          <h4 *ngIf="!errors">Thanks for verifying! Your account is all set up now. Feel free to close this tab and return to where you signed up from!</h4>
          <h4 *ngIf="errors" class="text-red">Oh no! {{errors}}</h4>
        </div>
      </tandem-loading-container>
    </ng-container>
    <ng-container *ngIf="mode === 'resetPassword' && newPassForm">
      <div class="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-background px-20">
        <h2>Reset Password</h2>
      </div>
      <tandem-form-input class="mt-40"
                         [inputType]="'password'"
                         [form]="newPassForm"
                         [controlName]="'newPassword'"
                         [placeholder]="'New Password'"
                         [label]="'New Password'"
                         [inputId]="'newPassword'"></tandem-form-input>
      <tandem-button theme="solid" color="primary"  [disabled]="newPassForm.invalid" [onClick]="saveNewPassword.bind(this)">Save</tandem-button>
      <div *ngIf="isLoaded" class="w-3/4 p-20 md:p-60 flex flex-col gap-20 md:gap-60">
        <h4 *ngIf="!errors">Your password has been changed. Please try to sign in with your new information.</h4>
        <h4 *ngIf="errors" class="text-red">{{errors}}</h4>
      </div>
    </ng-container>
  </div>
</div>


