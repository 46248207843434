import {
  ChangeDetectorRef,
  Component, ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges, ViewChild
} from '@angular/core';
import {TableColumn, TableConfiguration} from "../../../models/table-configuration";
import {CurrencyService} from "../../../services/currency.service";
import {DialogService} from "../../../services/dialog.service";
import {Overlay} from "@angular/cdk/overlay";
import {ModalRef} from "../../../models/modal-ref";
import {Asset} from "../../../../financial-positions/models/asset";
import {StatementItem} from "../../../models/statements/statement-item";
import {StatementItemDialogComponent} from "../statement-item-dialog/statement-item-dialog.component";
import {StatementCategory} from "../../../models/statements/statement-category";
import {DateService} from "../../../services/date.service";
import {Timestamp} from "firebase/firestore";

@Component({
  selector: 'tandem-statement-item-table',
  templateUrl: './statement-item-table.component.html',
  styleUrls: ['./statement-item-table.component.scss']
})
export class StatementItemTableComponent implements OnInit {

  @Input() statementItemCategory: StatementCategory = {name: 'Statement Items', items: [], total: 0};
  @Input() mode: 'create' | 'edit' | 'view' = 'create';
  @Input() displayDate: boolean = false;
  @Input() displayMoveOption: boolean = false;

  @ViewChild('inputField')
  inputField!: ElementRef;

  tableConfig: TableConfiguration<StatementItem> | undefined;
  tableData: StatementItem[] = [];

  @Output() changesMade: EventEmitter<void> = new EventEmitter<void>();
  @Output() itemMoved: EventEmitter<StatementItem> = new EventEmitter<StatementItem>();

  constructor(private currencyService: CurrencyService, private overlay: Overlay, private cdr: ChangeDetectorRef, private dateService: DateService,
              private dialogService: DialogService) {
  }

  ngOnInit(): void {
    this.tableConfig = {
      addFunction: () => this.openStatementItemDialog(),
      canBeHidden: false,
      columns: [
        {
          title: 'NAME',
          itemProperty: 'name',
          columnType: 'text',
          sortable: true
        },
        {
          title: 'VALUE',
          itemProperty: 'value',
          columnType: 'value',
          sortable: true,
          displayMask: (t: StatementItem) => {return this.currencyService.formatNumber(t.value)}
        }
      ],
      deleteRowFunction: (statementItem: StatementItem) => this.confirmDelete(statementItem),
      editRowFunction: (statementItem: StatementItem) => this.openStatementItemDialog(statementItem),
      moveRowFunction: this.mode === 'create' && this.displayMoveOption ? (statementItem: StatementItem) => this.moveToUncategorized(statementItem) : undefined,
      onRowClick: this.mode == 'view' ? undefined : (statementItem: StatementItem) => this.openStatementItemDialog(statementItem),
      noDataMessage: `No ${this.statementItemCategory.name}`,
      subtitle: this.statementItemCategory.name,
      hoverHint: this.statementItemCategory.hoverHint,
      totalProp: 'value',
      collapsible: true,
      collapsed: this.mode === 'view',
      draggable: this.mode !== 'view'
    };
    if (this.displayDate) {
      this.tableConfig.columns.push(
        {
          title: 'DATE',
          itemProperty: 'date',
          columnType: 'text',
          sortable: true,
          displayMask: (t: StatementItem) => {
            return t.date ? this.formatDate(Timestamp.fromMillis(1000 * (t.date.seconds || 0)).toDate()) : ''
          }
        })
    }
    if (this.mode === 'view') {
      this.tableConfig.addFunction = undefined;
      this.tableConfig.editRowFunction = undefined;
      this.tableConfig.deleteRowFunction = undefined;
    }
    this.tableData = this.statementItemCategory.items;
  }

  public openStatementItemDialog(inputStatementItem?: StatementItem): void {
    const ref: ModalRef = this.dialogService.openModal(StatementItemDialogComponent, {
      inputStatementItem: inputStatementItem,
      inputTitle: `${!inputStatementItem ? 'Add' : 'Modify'} ${this.statementItemCategory.name}`,
      requireDate: this.displayDate
    });
    ref.afterClosed().subscribe(statementItem => {
      if (statementItem) {
        if (inputStatementItem) {
          let i = this.statementItemCategory.items.indexOf(inputStatementItem);
          this.statementItemCategory.items[i] = statementItem;
        } else {
          this.statementItemCategory.items.push(statementItem);
        }
        this.tableData = [...this.statementItemCategory.items];
        this.statementItemCategory.items = [...this.statementItemCategory.items];
        this.cdr.detectChanges();
        this.changesMade.emit();
        this.inputField.nativeElement.focus();
      }
    })
  }

  public moveToUncategorized(item: StatementItem) {
    this.itemMoved.emit(item);
  }

  public confirmDelete(item: StatementItem) {
    this.dialogService
      .openConfirmDialog('Delete Item', `Are you sure you want to delete ${item.name}?`, 'Delete')
      .afterClosed().subscribe(confirmed => {
        if (confirmed) {
          let index: number = this.statementItemCategory.items.indexOf(item);
          this.statementItemCategory.items.splice(index, 1);
          this.statementItemCategory.items = [...this.statementItemCategory.items]
          this.cdr.detectChanges();
          this.changesMade.emit();
        }
    })
  }

  private formatDate(date: Date) {
    let day: string | number = date.getUTCDate();
    let month: string | number = date.getUTCMonth() + 1; // January is 0 in UTC as well
    let year = date.getUTCFullYear();

    // ensure double digits
    day = day < 10 ? '0' + day : day;
    month = month < 10 ? '0' + month : month;

    return month + '/' + day + '/' + year;
  }
}
