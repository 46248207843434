import {Component, Input} from '@angular/core';
import {Goal} from "../../../../dashboard/models/goal";
import {FormArray, FormBuilder, FormGroup, UntypedFormGroup} from "@angular/forms";

@Component({
  selector: 'tandem-form-checkbox-group',
  templateUrl: './form-checkbox-group.component.html',
  styleUrls: ['./form-checkbox-group.component.scss']
})
export class FormCheckboxGroupComponent {

  @Input() goals: Goal[] = [];

  checksForm = new UntypedFormGroup({});
  constructor(private fb: FormBuilder) {
  }

  ngOnInit(): void {

    this.checksForm = this.fb.group({
      goals: this.fb.array(this.goals.map(goal => this.fb.control(goal.completed)))
    });
  }

  get goalsFormArray() {
    return this.checksForm.get('goals') as FormArray;
  }

  protected readonly FormGroup = FormGroup;

  getFormGroup() {
    return this.checksForm.get('goals') as FormGroup
  }
}
