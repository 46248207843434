import { NgModule } from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import { LoginComponent } from './components/login/login.component';
import {AuthRoutingModule} from "./auth-routing.module";
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
import { AuthDirective } from './directives/auth.directive';
import { ProfileComponent } from './components/profile/profile.component';
import {TandemCoreModule} from "../tandem-core/tandem-core.module";
import {ReactiveFormsModule} from "@angular/forms";
import { CompleteRegistrationComponent } from './components/complete-registration/complete-registration.component';
import { EmailNotVerifiedComponent } from './components/email-not-verified/email-not-verified.component';
import { ChangeEmailDialogComponent } from './components/change-email-dialog/change-email-dialog.component';
import { ReauthWithEmailPassComponent } from './components/reauth-with-email-pass/reauth-with-email-pass.component';
import {HttpClientModule} from "@angular/common/http";
import {PlaidModule} from "../plaid/plaid.module";
import {StripeModule} from "../stripe/stripe.module";
import { RegisterComponent } from './components/register/register.component';
import { CreatingAccountComponent } from './components/creating-account/creating-account.component';
import { VerifyEmailComponent } from './components/verify-email/verify-email.component';
import { AdditionalInfoComponent } from './components/additional-info/additional-info.component';
import { ForgotPasswordDialogComponent } from './components/forgot-password-dialog/forgot-password-dialog.component';
import { AttemptAuthDialogComponent } from './components/attempt-auth-dialog/attempt-auth-dialog.component';



@NgModule({
  declarations: [
    LoginComponent,
    UnauthorizedComponent,
    AuthDirective,
    AuthDirective,
    ProfileComponent,
    CompleteRegistrationComponent,
    EmailNotVerifiedComponent,
    ChangeEmailDialogComponent,
    ReauthWithEmailPassComponent,
    RegisterComponent,
    CreatingAccountComponent,
    VerifyEmailComponent,
    AdditionalInfoComponent,
    ForgotPasswordDialogComponent,
    AttemptAuthDialogComponent,
  ],
  exports: [
    AuthDirective
  ],
    imports: [
        CommonModule,
        AuthRoutingModule,
        TandemCoreModule,
        ReactiveFormsModule,
        NgOptimizedImage,
        HttpClientModule,
        PlaidModule,
        StripeModule
    ]
})
export class AuthModule { }
