import {Component, Inject, OnInit} from '@angular/core';
import {ModalRef} from "../../../tandem-core/models/modal-ref";

@Component({
  selector: 'tandem-attempt-auth-dialog',
  templateUrl: './attempt-auth-dialog.component.html',
  styleUrls: ['./attempt-auth-dialog.component.scss']
})
export class AttemptAuthDialogComponent implements OnInit {

  isLoading = true;
  errorMessage?: string;

  constructor( @Inject(ModalRef) public modalRef: ModalRef) {
  }
  ngOnInit(): void {
    setTimeout(() => {
      this.errorMessage = `Hmm, it looks like we're running into an issue on our end creating your account. Please try creating your account again. If you get this message a second time, please reach out to support`
      this.isLoading = false;
    }, 30000)
  }

  onClose() {
    this.modalRef.close(true);
  }
}
