<div class="flex-row relative h-full" [ngClass]="{'pt-60': displayNavbar}">
  <tandem-sidenav2 *ngIf="user && !disableAllNav" class="fixed top-0 left-0 flex-grow" style="height: 100%;z-index: 52" [sidenavState]="sidenavState" (onToggle)="onSidenavToggled()">
  </tandem-sidenav2>
<!--  <div *ngIf="user && displaySidenav" class="fixed top-0 left-0 flex-grow bg-red w-300" style="height: 100%;z-index: 56" >-->
<!--  </div>-->
  <div class="fixed w-full h-full top-0 left-0" style="z-index: 51;background-color: rgba(0, 0, 0, .30)" (click)="closeSidenav()" *ngIf="sidenavState === 'expanded'"></div>

  <div class="relative">
    <tandem-toolbar *ngIf="user && !disableAllNav && displayNavbar" (sidenavOpened)="onSidenavOpened($event)"></tandem-toolbar>
    <tandem-grid/>
    <tandem-header *ngIf="!disableAllNav" [showUserCircle]="!screenIsSmall"></tandem-header>
    <div class="w-full">
      <div class="relative">
        <div [@routeAnimations]="o.isActivated ? o.activatedRoute : ''">
          <router-outlet #o="outlet"></router-outlet>
        </div>
      </div>
    </div>
  </div>
</div>
