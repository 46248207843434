
<div class="grid-container xl-max:w-full">
  <div class="flex flex-col items-center gap-20 mb-100">
    <img [src]="environment.logoPath" class="cols-6 sm:cols-4 h-auto">
    <h3>Welcome back! Sign in here.</h3>
    <div class="cols-8 p-20 border-2 border-primary rounded-sm flex flex-col items-center gap-20 md:mt-20">
      <tandem-form-input
        class="cols-6"
        [form]="loginForm"
        [controlName]="'email'"
        [inputType]="'text'"
        [inputId]="'email'"
        [placeholder]="'Email'"
        [label]="'Email'"></tandem-form-input>
      <tandem-form-input
        class="cols-6"
        [form]="loginForm"
        [controlName]="'password'"
        [inputType]="'password'"
        [inputId]="'password'"
        [placeholder]="'Password'"
        [label]="'Password'"></tandem-form-input>
      <tandem-button class="mt-15" theme="outline" [onClick]="signInWithEmail.bind(this)" [disabled]="loginForm.invalid">Sign In With Email And Password</tandem-button>
      <p class="text-red" *ngIf="!!errorMessage">{{errorMessage}}</p>
      <h4>OR</h4>
      <tandem-button theme="outline" color="red" [onClick]="onGoogleLogin.bind(this)">
        <div class="flex flex-row justify-around items-center">
          <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="40" height="40" viewBox="0 0 48 48">
            <path fill="#FFC107" d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12c0-6.627,5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24c0,11.045,8.955,20,20,20c11.045,0,20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"></path><path fill="#FF3D00" d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"></path><path fill="#4CAF50" d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"></path><path fill="#1976D2" d="M43.611,20.083H42V20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"></path>
          </svg>
          <span class="ps-20">Sign in with Google</span>
        </div>
      </tandem-button>
      <div class="flex flex-col gap-10 text-center">
        <h6 class="text-primary">Forgot your password? <a class="underline hover:cursor-pointer hover:text-primary/60" (click)="forgotPassword()">Reset here</a> </h6>
        <h6 class="text-primary">Need to setup an account? <a class="underline hover:cursor-pointer hover:text-primary/60" (click)="routeWithParams('/auth/register')">Sign Up Here</a> </h6>
      </div>
      <div class="flex flex-col gap-5 text-center">
        <p>&copy; 2023 Tandem Finance. All rights reserved.</p>
        <p>{{envName}}</p>
      </div>
    </div>
  </div>
</div>




<!--<div class="grid-container xl-max:w-full">-->
<!--  <div class="w-full flex flex-col items-center gap-120">-->
<!--    <div class="w-full flex flex-col items-center gap-30">-->
<!--      <img ngSrc="assets/svg/logo/full_blue.svg" height="400" width="400" class=""/>-->
<!--      <h3>Welcome back! Sign in here.</h3>-->
<!--      <div class="flex flex-col py-30 px-120 gap-20 rounded-sm border-2 border-primary xs:cols-4 xs:cols-ml-1">-->
<!--        <tandem-form-input-->
<!--          [form]="loginForm"-->
<!--          [controlName]="'email'"-->
<!--          [inputType]="'text'"-->
<!--          [inputId]="'email'"-->
<!--          [placeholder]="'Email'"-->
<!--          [label]="'Email'"></tandem-form-input>-->
<!--        <tandem-form-input-->
<!--          [form]="loginForm"-->
<!--          [controlName]="'password'"-->
<!--          [inputType]="'password'"-->
<!--          [inputId]="'password'"-->
<!--          [placeholder]="'Password'"-->
<!--          [label]="'Password'"></tandem-form-input>-->
<!--        <tandem-button class="mt-15" theme="outline" [onClick]="signInWithEmail.bind(this)" [disabled]="loginForm.invalid">Sign In With Email And Password</tandem-button>-->
<!--        <p class="text-red" *ngIf="!!errorMessage">{{errorMessage}}</p>-->
<!--        <h4 class="text-center">OR</h4>-->
<!--        <tandem-button theme="outline" color="red" [onClick]="onGoogleLogin.bind(this)">-->
<!--          <div class="flex flex-row justify-around items-center">-->
<!--            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="40" height="40" viewBox="0 0 48 48">-->
<!--              <path fill="#FFC107" d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12c0-6.627,5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24c0,11.045,8.955,20,20,20c11.045,0,20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"></path><path fill="#FF3D00" d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"></path><path fill="#4CAF50" d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"></path><path fill="#1976D2" d="M43.611,20.083H42V20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"></path>-->
<!--            </svg>-->
<!--            <span class="ps-20">Sign in with Google</span>-->
<!--          </div>-->
<!--        </tandem-button>-->
<!--        <p class="my-20 text-14 text-primary">Need to setup an account? <a class="underline hover:cursor-pointer hover:text-primary/60" [routerLink]="'/auth/register'">Sign Up Here</a> </p>-->
<!--      </div>-->
<!--      <p>&copy; 2023 Tandem Finance. All rights reserved.</p>-->
<!--      <p>{{envName}}</p>-->
<!--    </div>-->

<!--  </div>-->
<!--</div>-->


<!--<div class="grid-container xl-max:w-full">-->
<!--  <div class="w-full {{lightMode ? 'bg-background' : 'bg-primary'}} flex flex-col justify-center items-center px-20" style="height: 100vh">-->
<!--    <div class="w-full flex flex-col items-center gap-30">-->
<!--      <img ngSrc="assets/svg/logo/full_blue.svg" height="400" width="400" class=""/>-->
<!--      <h3>Welcome back! Sign in here.</h3>-->
<!--      <div class="flex flex-col py-30 px-120 gap-20 rounded-sm border-2 border-primary">-->
<!--        <tandem-form-input-->
<!--          [form]="loginForm"-->
<!--          [controlName]="'email'"-->
<!--          [inputType]="'text'"-->
<!--          [inputId]="'email'"-->
<!--          [placeholder]="'Email'"-->
<!--          [label]="'Email'"></tandem-form-input>-->
<!--        <tandem-form-input-->
<!--          [form]="loginForm"-->
<!--          [controlName]="'password'"-->
<!--          [inputType]="'password'"-->
<!--          [inputId]="'password'"-->
<!--          [placeholder]="'Password'"-->
<!--          [label]="'Password'"></tandem-form-input>-->
<!--        <tandem-button class="mt-15" theme="outline" [onClick]="signInWithEmail.bind(this)" [disabled]="loginForm.invalid">Sign In With Email And Password</tandem-button>-->
<!--        <p class="text-red" *ngIf="!!errorMessage">{{errorMessage}}</p>-->
<!--        <h4 class="text-center">OR</h4>-->
<!--        <tandem-button theme="outline" color="red" [onClick]="onGoogleLogin.bind(this)">-->
<!--          <div class="flex flex-row justify-around items-center">-->
<!--            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="40" height="40" viewBox="0 0 48 48">-->
<!--              <path fill="#FFC107" d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12c0-6.627,5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24c0,11.045,8.955,20,20,20c11.045,0,20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"></path><path fill="#FF3D00" d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"></path><path fill="#4CAF50" d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"></path><path fill="#1976D2" d="M43.611,20.083H42V20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"></path>-->
<!--            </svg>-->
<!--            <span class="ps-20">Sign in with Google</span>-->
<!--          </div>-->
<!--        </tandem-button>-->
<!--        <p class="my-20 text-14 text-primary">Need to setup an account? <a class="underline hover:cursor-pointer hover:text-primary/60" [routerLink]="'/auth/register'">Sign Up Here</a> </p>-->
<!--      </div>-->
<!--      <p>&copy; 2023 Tandem Finance. All rights reserved.</p>-->
<!--      <p>{{envName}}</p>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->

