import {
  ChangeDetectorRef,
  Component, ElementRef, HostListener,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import {
  HeaderConfig,
  HeaderService,
} from '../../../../modules/tandem-core/services/header.service';
import { AuthService } from '../../../../modules/auth/services/auth.service';
import { TandemUser } from '../../../../modules/auth/models/tandem-user';
import {Router} from "@angular/router";
import {ThemingService} from "../../../../modules/tandem-core/services/theming.service";
import {CoreService} from "../../../../modules/tandem-core/services/core.service";
import {PaymentService} from "../../../../modules/stripe/services/payment-service";
import {DialogService} from "../../../../modules/tandem-core/services/dialog.service";
import {VideoPlayerComponent} from "../../../../modules/tandem-core/components/video-player/video-player.component";

@Component({
  selector: 'tandem-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit, OnChanges {
  @Input() headerConfig: HeaderConfig = {};
  @Input() showUserCircle: boolean = true;
  user: TandemUser | null = null;

  themes = [
    'purple',
    'blue',
    'fuscia',
    'teal',
    'navy',
    'spring-green',
    'sea-green',
    'forest',
    'orange',
  ];
  userIsPaid = false;
  // showHeader = false;
  // bannerMessage = 'Your account does not have an active subscription. Only the calculator tools are available to use for free. Click to activate your subscription.';
  displayUserDropdown = false;

  constructor(
    private headerService: HeaderService,
    private auth: AuthService,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private themingService: ThemingService,
    private coreService: CoreService,
    private paymentService: PaymentService,
    private dialogService: DialogService,
    private _elementRef: ElementRef
  ) {}

  ngOnInit(): void {
    this.auth.userIsPaid.subscribe(isPaid => this.userIsPaid = isPaid);
    this.auth.$user.subscribe((user) => (this.user = user));
    this.headerService.getConfig().subscribe(config => {
      this.headerConfig = config
      this.cdr.detectChanges();
    });
    this.themingService.screenSize.subscribe(size => {
      // this.showHeader = ['lg', 'xl', 'xxl'].includes(size);
    });
    // this.coreService.addSubscription('headerPaidSub', this.auth.userIsPaid.subscribe(isPaid => this.userIsPaid = isPaid));
    // this.coreService.addSubscription('headerAuthSub', this.auth.$user.subscribe((user) => (this.user = user)));
    // this.coreService.addSubscription('headerConfigSub', this.headerService.getConfig().subscribe(config => {
    //   this.headerConfig = config
    //   this.cdr.detectChanges();
    // }));
    // this.coreService.addSubscription('headerThemingSub', this.themingService.screenSize.subscribe(size => {
    //   console.log(size)
    //   // this.showHeader = ['lg', 'xl', 'xxl'].includes(size);
    // }))

  }

  ngOnChanges(changes: SimpleChanges): void {

  }

  swapTheme() {
    const html = document.documentElement;
    const attr = 'data-theme';
    let random = Math.floor(Math.random() * this.themes.length);
    let randomizedColor = this.themes[random];

    this.themingService.setTheme(randomizedColor);
    html.setAttribute(attr, randomizedColor);
  }

  route(path: string) {
    this.router.navigate([path]);
  }

  onVisionChange() {
    this.headerService.visionChanged.emit();
  }

  openStripe() {
    this.router.navigate(['auth/profile']).then(routed => {
      this.paymentService.openStripe();
    })
  }

  showVideo() {
    this.dialogService.openModal(VideoPlayerComponent);
  }

  onLogout() {
    this.auth.onLogout();
  }

  toggleUserDropdown() {
    this.displayUserDropdown = !this.displayUserDropdown;
  }

  @HostListener('document:click', ['$event'])
  clickOutside(event: MouseEvent): void {
    if (this._elementRef.nativeElement.querySelector('tandem-icon[icon="userCircle"]')) {
      const clickedInsideDropdown = this._elementRef.nativeElement.querySelector('.absolute.top-full').contains(event.target);
      const clickedOnIcon = this._elementRef.nativeElement.querySelector('tandem-icon[icon="userCircle"]').contains(event.target);

      if (!clickedInsideDropdown && !clickedOnIcon) {
        this.displayUserDropdown = false;
      }
    }
  }
}
