import {Component, OnInit} from '@angular/core';
import {PaymentService} from "../../services/payment-service";
import {HeaderService} from "../../../tandem-core/services/header.service";
import {DialogService} from "../../../tandem-core/services/dialog.service";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'tandem-payment-success',
  templateUrl: './payment-success.component.html',
  styleUrls: ['./payment-success.component.scss']
})
export class PaymentSuccessComponent implements OnInit {

  sessionId: string | null = null;
  errorMessage?: string;
  isLoaded = false;
  constructor(private paymentService: PaymentService,
              private dialogService: DialogService,
              private headerService: HeaderService,
              private route: ActivatedRoute) {
    this.sessionId = this.route.snapshot.queryParamMap.get('session_id');
  }
  ngOnInit(): void {
    this.headerService.setConfig({hideHeader: true});
    if (this.sessionId) {
      this.paymentService.validateAccessFromSession(this.sessionId).then(res => {

      }).catch(err => {
        if (err.statusText == 'Unknown Error') {
          this.errorMessage = 'Something went wrong on our end. Please refresh the page. If the error persists, contact customer support.'
        } else {
          this.errorMessage = err;
        }
      })
    }
  }

}
