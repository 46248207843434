<div class="inline-flex items-center gap-5 hover:cursor-pointer mr-10">
  <div *ngIf="!control?.value" class="w-16 h-16 border border-primary" style="border-radius: 3px" (click)="$event.stopPropagation(); onToggle()">
  </div>
  <svg *ngIf="control?.value" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" (click)="$event.stopPropagation(); onToggle()">
    <rect width="16" height="16" rx="3" fill="#4660EA"/>
    <path d="M5.85 10.0125L3 7.1625L3.7125 6.45L5.85 8.5875L10.4375 4L11.15 4.7125L5.85 10.0125Z" fill="#F8F7F8"/>
  </svg>
  <label class="text-14 flex flex-row gap-10 items-center ml-4" [for]="inputId">
    {{label}}
    <span *ngIf="needsButton"><tandem-button class="w-14 h-14 " variant="circle" icon="plus" theme="outline"></tandem-button></span>
  </label>

<!--    <input-->
<!--      class="w-14 h-14 rounded-xs border border-solid border-primary"-->
<!--      [id]="inputId"-->
<!--      type="checkbox"-->
<!--      [formControl]="getControl()">-->
<!--&lt;!&ndash;      [disabled]="isDisabled"&ndash;&gt;-->
<!--&lt;!&ndash;    >&ndash;&gt;-->
<!--  <label class="text-14 flex flex-row gap-10 items-center" [for]="inputId">-->
<!--    {{label}}-->
<!--    <span *ngIf="needsButton"><tandem-button class="w-14 h-14 " variant="circle" icon="plus" theme="outline"></tandem-button></span>-->
<!--  </label>-->
</div>
