import {NgModule} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { AppComponent } from './app.component';
import { environment } from '../environments/environment';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NavItemComponent } from './components/nav/nav-item/nav-item.component';
import { SidenavComponent } from './components/nav/sidenav/sidenav.component';
import { AuthModule } from './modules/auth/auth.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { OverlayModule } from '@angular/cdk/overlay';
import { HeaderComponent } from './components/nav/sidenav/header/header.component';
import { TandemCoreModule } from './modules/tandem-core/tandem-core.module';
import { GridComponent } from './components/grid/grid';
import {NgxStripeModule} from "ngx-stripe";
import {NgxEchartsModule} from "ngx-echarts";
import { ToolbarComponent } from './components/toolbar/toolbar.component';
import { Sidenav2Component } from './components/nav/sidenav2/sidenav2.component';
import {ClipboardModule} from "@angular/cdk/clipboard";
@NgModule({
  declarations: [
    AppComponent,
    NavItemComponent,
    SidenavComponent,
    HeaderComponent,
    GridComponent,
    ToolbarComponent,
    Sidenav2Component,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireStorageModule,
    AngularFireDatabaseModule,
    AuthModule,
    FontAwesomeModule,
    OverlayModule,
    TandemCoreModule,
    NgxStripeModule.forRoot(environment.stripePublicKey),
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts')
    }),
    ClipboardModule
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
