import {Component, HostListener, Inject} from '@angular/core';
import {DialogService} from "../../services/dialog.service";
import {ModalRef} from "../../models/modal-ref";

@Component({
  selector: 'tandem-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent {

  title: string = '';
  message: string = '';
  confirmText: string = 'Confirm';
  cancelText: string = 'Cancel';

  hideCancel = false;
  hideConfirm = false;

  confirmIsPrimary: boolean = false;

  constructor(dialogService: DialogService,
              @Inject(ModalRef) public modalRef: ModalRef) {
    this.title = modalRef.data?.title;
    this.message = modalRef.data?.message;
    this.confirmText = modalRef.data?.confirmText;
    this.cancelText = modalRef.data?.cancelText;
    this.hideCancel = modalRef.data?.hideCancel;
    this.hideConfirm = modalRef.data?.hideConfirm;
    this.confirmIsPrimary = modalRef.data?.confirmIsPrimary;
  }

  closeModal(result: boolean) {
    this.modalRef.close(result)
  }

  @HostListener('document:keydown.enter', ['$event'])
  onEnterPressed(event: KeyboardEvent): void {
    this.closeModal(true);
  }
}
