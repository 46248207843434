import { NgModule } from '@angular/core';
import {CommonModule, CurrencyPipe, DatePipe, PercentPipe} from '@angular/common';
import { ChartComponent } from './components/charts/chart/chart.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ButtonComponent } from './components/button/button.component';
import { FormCheckboxComponent } from './components/forms/form-checkbox/form-checkbox.component';
import { FormInputComponent } from './components/forms/form-input/form-input.component';
import { FormSelectComponent } from './components/forms/form-select/form-select.component';
import { FormTextareaComponent } from './components/forms/form-textarea/form-textarea.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { RouterLink } from '@angular/router';
import { LoadingContainerComponent } from './components/loading-container/loading-container.component';
import { CardComponent } from './components/cards/card/card.component';
import { FormCheckboxGroupComponent } from './components/forms/form-checkbox-group/form-checkbox-group.component';
import { FormSwitchComponent } from './components/forms/form-switch/form-switch.component';
import {TableComponent} from "./components/table/table.component";
import {HeadItemComponent} from "./components/table/head-item/head-item.component";
import {LineItemComponent} from "./components/table/line-item/line-item.component";
import {AddNewRowComponent} from "./components/table/add-new-row/add-new-row.component";
import {PaginationComponent} from "./components/table/pagination/pagination.component";
import { AbstractModalComponent } from './components/abstract-modal/abstract-modal.component';
import {StatementItemTableComponent} from "./components/statements/statement-item-table/statement-item-table.component";
import {
  StatementItemDialogComponent
} from "./components/statements/statement-item-dialog/statement-item-dialog.component";
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import {HttpClientModule} from "@angular/common/http";
import { IconComponent } from './components/icons/icon/icon.component';
import { TooltipDirective } from './directives/tooltip.directive';
import { TooltipComponent } from './components/tooltip/tooltip.component';
import {LoadingDialogComponent} from "./components/loading-dialog/loading-dialog.component";
import {CdkDrag, CdkDropList, CdkDropListGroup, DragDropModule} from "@angular/cdk/drag-drop";
import { VideoPlayerComponent } from './components/video-player/video-player.component';
import {CdkMenu, CdkMenuItem, CdkMenuTrigger} from "@angular/cdk/menu";

@NgModule({
  declarations: [
    AbstractModalComponent,
    AddNewRowComponent,
    ButtonComponent,
    CardComponent,
    ChartComponent,
    FormCheckboxComponent,
    FormCheckboxGroupComponent,
    FormInputComponent,
    FormSelectComponent,
    FormSwitchComponent,
    FormTextareaComponent,
    HeadItemComponent,
    LineItemComponent,
    LoadingContainerComponent,
    PaginationComponent,
    StatementItemDialogComponent,
    StatementItemTableComponent,
    TableComponent,
    ConfirmDialogComponent,
    IconComponent,
    TooltipDirective,
    TooltipComponent,
    LoadingDialogComponent,
    VideoPlayerComponent,
  ],
  exports: [
    ButtonComponent,
    CardComponent,
    ChartComponent,
    FormCheckboxComponent,
    FormInputComponent,
    FormSelectComponent,
    FormSwitchComponent,
    FormTextareaComponent,
    IconComponent,
    LoadingContainerComponent,
    StatementItemDialogComponent,
    StatementItemTableComponent,
    TableComponent,
    TooltipDirective,
    TooltipComponent,
    LoadingDialogComponent
  ],

  imports: [
    CommonModule,
    FontAwesomeModule,
    NgxChartsModule,
    ReactiveFormsModule,
    RouterLink,
    HttpClientModule,
    FormsModule,
    CdkDrag,
    CdkDropList,
    CdkDropListGroup,
    DragDropModule,
    CdkMenuTrigger,
    CdkMenuItem,
    CdkMenu
  ],
  providers: [
    CurrencyPipe,
    DatePipe,
    PercentPipe
  ]
})
export class TandemCoreModule {}
