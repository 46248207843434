import {Component, Inject, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ModalRef} from "../../../models/modal-ref";
import {StatementItem} from "../../../models/statements/statement-item";
import {Timestamp} from "firebase/firestore";
import {DateService} from "../../../services/date.service";

@Component({
  selector: 'tandem-statement-item-dialog',
  templateUrl: './statement-item-dialog.component.html',
  styleUrls: ['./statement-item-dialog.component.scss']
})
export class StatementItemDialogComponent implements OnInit {

  inputItem: StatementItem | undefined;
  inputTitle: string = 'Modify Item';

  statementItemForm: FormGroup = new FormGroup<any>({});
  requireDate = false;

  constructor(private fb: FormBuilder,
              private dateService: DateService,
              @Inject(ModalRef) public modalRef: ModalRef) {
    this.inputItem = this.modalRef.data?.inputStatementItem;
    this.inputTitle = this.modalRef.data?.inputTitle;
    this.requireDate = this.modalRef.data?.requireDate;
  }

  ngOnInit(): void {
    this.statementItemForm = this.fb.group({
      name: [this.inputItem?.name, Validators.required],
      value: [this.inputItem?.value, [Validators.required, Validators.min(0)]],
      date: [this.inputItem?.date ? this.inputItem.date.toDate().toISOString().substring(0, 10) : new Date().toISOString().substring(0, 10)]
    });
    if (this.requireDate) {
      this.statementItemForm.get('date')?.addValidators(Validators.required);
    }
    this.statementItemForm.get('value')?.valueChanges.subscribe(hehe => {
      let userInput = hehe;
      const segments: string[] =  userInput.toString().split('.');
      if (hehe?.toString().length > 12) {
        hehe = hehe?.toString().slice(0, 12);
        this.statementItemForm.get('value')?.setValue(parseInt(hehe, 10));
      }
      if (segments[1]?.length > 2) {
        userInput = `${segments[0]}.${segments[1].substring(0, 2)}`
        this.statementItemForm.get('value')?.setValue(userInput);
      }
    })
  }



  public onSubmit() {
    this.modalRef.close({
      name: this.statementItemForm.get('name')?.value,
      value: Number(this.statementItemForm.get('value')?.value),
      date: this.statementItemForm.get('date')?.value ? this.dateService.getAdjustedTimestampFromDate(new Date(this.statementItemForm.get('date')?.value)) : null,
    });

  }

  public onCancel() {
    this.modalRef.close();
  }

  onFormSubmit() {
    if (!this.statementItemForm.invalid) {
      this.onSubmit();
    }
  }
}
