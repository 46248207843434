import { OverlayRef } from '@angular/cdk/overlay';
import { Subject } from 'rxjs';

export class ModalRef {

  public data: any;
  private afterClosedSubject: Subject<any> = new Subject<any>();

  constructor(private overlayRef: OverlayRef, data?: any) {
    this.data = data;
    overlayRef.detachments().subscribe(() => {
      this.afterClosedSubject.complete();
    });
  }

  close(result?: any): void {
    this.afterClosedSubject.next(result);
    this.overlayRef.dispose();
  }

  // listenToEscapeKey() {
  //   document.addEventListener('keydown',
  //     this.es)
  // }
  afterClosed() {
    return this.afterClosedSubject.asObservable();
  }
}
