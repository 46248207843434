import {Component, OnInit} from '@angular/core';
import {AuthService} from "../../services/auth.service";
import {Router} from "@angular/router";
import {AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators} from "@angular/forms";
import {DialogService} from "../../../tandem-core/services/dialog.service";
import {environment} from "../../../../../environments/environment";
import {HeaderService} from "../../../tandem-core/services/header.service";
import {ForgotPasswordDialogComponent} from "../forgot-password-dialog/forgot-password-dialog.component";


@Component({
  selector: 'tandem-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  public loginForm: FormGroup = new FormGroup<any>({});

  public mode: 'login' | 'register' = 'login';

  lightMode = true;

  errorMessage: string | null = null;
  envName: string = environment.environmentName;

  constructor(private auth: AuthService,
              private router: Router,
              private dialogService: DialogService,
              private headerService: HeaderService,
              private fb: FormBuilder) {
  }

  ngOnInit() {
    this.headerService.resetConfig();
    this.loginForm = this.fb.group({
      email: [null, [Validators.required, Validators.email]],
      password: [null, Validators.required],
    });
  }

  onGoogleLogin() {
    this.auth.onGoogleLogin('login').then(r => {}).then(res => {
      this.router.navigate(['/dashboard'])
      this.dialogService.showAlert({title: 'Logged In', content: 'Welcome!', dismissTime: 3000, position: 'bottom'})
    })
  }

  public signInWithEmail() {
    this.auth.signInWithEmailPass(
      this.loginForm.get('email')?.value,
      this.loginForm.get('password')?.value
    )
  }

    protected readonly environment = environment;

  forgotPassword() {
    this.dialogService.openModal(ForgotPasswordDialogComponent);
  }

  routeWithParams(s: string) {
    this.router.navigate([s], {
      queryParamsHandling: 'preserve'
    })
  }
}
