import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {LoginComponent} from "./components/login/login.component";
import {UnauthorizedComponent} from "./components/unauthorized/unauthorized.component";
import {ProfileComponent} from "./components/profile/profile.component";
import {AuthGuard} from "./guards/auth-guard";
import {CompleteRegistrationComponent} from "./components/complete-registration/complete-registration.component";
import {EmailNotVerifiedComponent} from "./components/email-not-verified/email-not-verified.component";
import {NotSignedInGuard} from "./guards/not-signed-in-guard";
import {RegisterComponent} from "./components/register/register.component";
import {CreatingAccountComponent} from "./components/creating-account/creating-account.component";
import {VerifyEmailComponent} from "./components/verify-email/verify-email.component";
import {AdditionalInfoComponent} from "./components/additional-info/additional-info.component";

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [NotSignedInGuard]
  },
  {
    path: 'register',
    component: RegisterComponent,
    canActivate: [NotSignedInGuard]
  },
  {
    path: 'unauthorized',
    component: UnauthorizedComponent
  },
  {
    path: 'creating-account',
    component: CreatingAccountComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'additional-info',
    component: AdditionalInfoComponent,
    canActivate: []
  },
  {
    path: 'email-not-verified',
    component: EmailNotVerifiedComponent,
  },
  {
    path: 'verify-email',
    component: VerifyEmailComponent,
  },
  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'completeRegistration',
    component: CompleteRegistrationComponent,
    canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule { }
