import {Component, Input} from '@angular/core';
import {ThemeType} from "../../models/theme-type";

@Component({
  selector: 'tandem-loading-container',
  templateUrl: './loading-container.component.html',
  styleUrls: ['./loading-container.component.scss']
})
export class LoadingContainerComponent {
  @Input() isLoaded: boolean = false;
  @Input() theme: ThemeType = 'primary';

}
