<ng-container *ngIf="!!control">
  <div class="inline-flex flex-col items-start gap-5 w-full">
    <p class="w-full">{{ label }}</p>

    <select
      [id]="inputId"
      [formControl]="displayControl"

      class="flex h-35 items-center gap-8 border border-dark/20 rounded-sm w-full"
    >
      <option [value]="null" >Select an Option</option>
      <option *ngFor="let option of displayOptions" [value]="option">
        {{ option }}
      </option>
    </select>
    <div *ngIf="control.invalid && (control.dirty || control.touched)" class="text-12 text-red">
      <!-- You can customize this part to show specific error messages -->
      <div *ngIf="control.hasError('required')">{{ label }} is required</div>
    </div>
  </div>
</ng-container>
