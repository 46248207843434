import { Injectable } from '@angular/core';
import {AngularFireAuth} from "@angular/fire/compat/auth";
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {BehaviorSubject, combineLatest, Observable} from "rxjs";
import {TandemUser} from "../models/tandem-user";
import firebase from "firebase/compat/app";
import {ActivatedRoute, Router} from "@angular/router";
import {Timestamp} from "firebase/firestore";
import {DialogService} from "../../tandem-core/services/dialog.service";
import {map, switchMap, take} from "rxjs/operators";
import {ModalRef} from "../../tandem-core/models/modal-ref";
import {ThemingService} from "../../tandem-core/services/theming.service";
import {LocalStorageService} from "../../tandem-core/services/local-storage.service";
import {RegistrationAttemptService} from "./registration-attempt.service";
import {HeaderService} from "../../tandem-core/services/header.service";
import {CoreService} from "../../tandem-core/services/core.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {AngularFireFunctions} from "@angular/fire/compat/functions";
import {AttemptAuthDialogComponent} from "../components/attempt-auth-dialog/attempt-auth-dialog.component";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  $user: BehaviorSubject<TandemUser | null> = new BehaviorSubject<TandemUser | null>(null);

  coachId: string | null = null;

  loadingMessage: string = '';
  userIsPaid: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private loadingDialog?: ModalRef;
  private creationLoadingDialog?: ModalRef;

  private serverPath = environment.functionsPath;

  userPermsSubject: BehaviorSubject<void> = new BehaviorSubject<void>(undefined);
  $userPerms: Observable<void> = this.userPermsSubject.asObservable();

  constructor(private auth: AngularFireAuth,
              private afs: AngularFirestore,
              private route: ActivatedRoute,
              private router: Router,
              private dialogService: DialogService,
              private localStorageService: LocalStorageService,
              private registrationAttemptService: RegistrationAttemptService,
              private themingService: ThemingService,
              private headerService: HeaderService,
              private coreService: CoreService,
              private http: HttpClient
  ) {
    this.coachId = this.route.snapshot.queryParamMap.get('coachId');

    combineLatest([
      this.auth.authState,
      this.auth.authState.pipe(
        switchMap(firebaseUser => {
          this.coachId = this.route.snapshot.queryParamMap.get('coachId');
          if (firebaseUser) {
            return this.afs.doc<TandemUser>(`users/${firebaseUser.uid}`).valueChanges();
          } else {
            return [null];
          }
        })
      )
    ]).subscribe(([firebaseUser, tandemUser]) => {
      if (firebaseUser) {
        firebaseUser.getIdTokenResult().then(res => {
          this.userIsPaid.next(res.claims['paid'] === true || res.claims['isPaid']);
        })
      } else {
        this.userIsPaid.next(false);
      }
      if (firebaseUser && tandemUser) {
        this.themingService.setTheme(tandemUser.theme || 'blue');
        // Do we need to set a coachID? (this is just after account creation with Google)
        if (tandemUser.checkForCoach && this.coachId) {
          this.afs.doc<TandemUser>(`users/${firebaseUser.uid}`).update({
            coachId: this.coachId,
            checkForCoach: false
          });
        } else {
          this.$user.next(tandemUser);
          if (!tandemUser.verified) {
            if (firebaseUser.emailVerified) {
              this.afs.collection<TandemUser>('users').doc(firebaseUser.uid).update({verified: true}).then(res => {
                this.loadingDialog?.close();
                this.loadingDialog = undefined;
                this.clearLoadingDialogs();
                this.router.navigate(['dashboard']);
              })
            } else {
              this.clearLoadingDialogs();
              this.router.navigate(['/auth/email-not-verified'])
            }
          } else {
            if (tandemUser.firstLogin || tandemUser.needsTokenRefresh) {
              firebaseUser.getIdToken(true).then(idToken => {
                this.afs.collection<TandemUser>('users').doc(firebaseUser.uid).update({
                  firstLogin: false,
                  needsTokenRefresh: false
                }).then(res => {
                  this.userPermsSubject.next();
// Check if the current route contains 'verify-email'
                  const currentRoute = this.route.snapshot.url.map(segment => segment.path).join('/');
                  if (!currentRoute.includes('verify-email') && !currentRoute.includes('payment-success')) {
                    this.loadingDialog?.close();
                    this.loadingDialog = undefined;
                    this.clearLoadingDialogs();
                    this.router.navigate(['dashboard']);
                  }
                });
              });
            }
            if (tandemUser.promptPaymentSuccess) {
              this.afs.collection<TandemUser>('users').doc(firebaseUser.uid).update({
                promptPaymentSuccess: false
              }).then(updated => {
                this.userPermsSubject.next();
                this.clearLoadingDialogs();
                this.router.navigate(['dashboard']).then(navigated => {
                  this.dialogService.openMessageDialog('Premium Unlocked!', `Congratulations on unlocking your premium account, you now have access to all features on the platform. If you need a refresh, click the "Need a Refresh" link on your dashboard for a comprehensive demo.`)
                })
              })
            }
          }
        }
      } else {
        this.$user.next(null);
      }
    });

    //
    //
    // this.auth.authState.subscribe(firebaseUser => {
    //   console.log(firebaseUser)
    //   if (firebaseUser) {
    //     this.afs.doc<TandemUser>(`users/${firebaseUser.uid}`).valueChanges().subscribe(tandemUser => {
    //       if (tandemUser) {
    //         this.themingService.setTheme(tandemUser.theme || 'blue');
    //         // Do we need to set a coachID? (this is just after account creation with Google)
    //         if (tandemUser.checkForCoach && this.coachId) {
    //           this.afs.doc<TandemUser>(`users/${firebaseUser.uid}`).update({
    //             coachId: this.coachId,
    //             checkForCoach: false
    //           });
    //         } else {
    //           this.$user.next(tandemUser);
    //           if (!tandemUser.verified) {
    //             this.router.navigate(['/auth/email-not-verified'])
    //           } else {
    //             if (tandemUser.firstLogin) {
    //               firebaseUser.getIdToken(true).then(idToken => {
    //                 this.afs.collection<TandemUser>('users').doc(firebaseUser.uid).update({firstLogin: false}).then(res => {
    //                   this.router.navigate(['dashboard']);
    //                 });
    //               });
    //             }
    //           }
    //         }
    //       } else {
    //         this.$user.next(tandemUser || null);
    //       }
    //     });
    //   } else {
    //     this.$user.next(null);
    //   }
    // })
  }

  public reloadUser() {
    this.auth.authState.pipe(
      take(1)
    ).subscribe(firebaseUser => {
      firebaseUser?.getIdToken(true).then(token => {
        const base64Url = token.split('.')[1]; // Get the payload part of the token
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        const payload = JSON.parse(jsonPayload);

        // Check if the email is verified
        if (payload.email_verified) {
          this.afs.collection<TandemUser>('users').doc(firebaseUser.uid).update({verified: true}).then(res => {
            this.clearLoadingDialogs();
            this.router.navigate(['dashboard']);
          })
        } else {
        }
      });
    })
  }

  public registerWithEmailPass(email: string, pass: string, coachId: string | null) {
    this.creationLoadingDialog = this.dialogService.openModal(AttemptAuthDialogComponent);
    this.creationLoadingDialog.afterClosed().subscribe(failed => {
      if (failed) {
        this.deleteCurrentUser();
      }
    })
    //this.loadingDialog = this.dialogService.openLoadingDialog('Creating User', 'Please wait, we are processing your information');
    this.registrationAttemptService.add({
      email: email,
      coachId: coachId,
      dateCreated: Timestamp.now(),
      dateModified: Timestamp.now(),
    }).then(res => {
      this.auth.createUserWithEmailAndPassword(email, pass)
        .then(r => {
          // this.loadingDialog?.close();
          // this.loadingDialog = undefined;
        }).catch(error => {
        let errorMessage = '';
        switch (error.code) {
          case 'auth/email-already-in-use':
            errorMessage = "The email address you entered is already in use by another account. Please use a different email address or sign in using the existing account.";
            break;
          case 'auth/invalid-email':
            errorMessage = "The email address you entered is not valid. Please check it and try again.";
            break;
          case 'auth/operation-not-allowed':
            errorMessage = "Signing in with email and password is currently not enabled. Please contact your administrator or check the Firebase Console under the Auth tab to enable it.";
            break;
          case 'auth/weak-password':
            errorMessage = "Looks like you need a stronger password. Make sure your password has at least one capital letter, one special symbol, and is a minimum of 8 characters long.";
            break;
          default:
            errorMessage = "An unexpected error occurred. Please try again.";
            break;
        }
        this.clearLoadingDialogs();
        this.dialogService.openMessageDialog('Unexpected Error', errorMessage);
      })
    });
  }

  public async onGoogleLogin(method: 'register' | 'login') {
    try {
      const provider = new firebase.auth.GoogleAuthProvider();
      const credential = await this.auth.signInWithPopup(provider).then(signedIn => {
         if (signedIn.additionalUserInfo?.isNewUser) {
           this.creationLoadingDialog = this.dialogService.openModal(AttemptAuthDialogComponent);
         this.creationLoadingDialog.afterClosed().subscribe(failed => {
             if (failed) {
               this.deleteCurrentUser();
             }
           })
         }
      });
      // if (method === 'register') {
      //   this.loadingDialog = this.dialogService.openLoadingDialog(`Creating User`, 'Please wait, we are processing your information');
      // }
      // this.loadingDialog?.close();
      // this.loadingDialog = undefined;
    } catch (e: any) {
      this.loadingDialog?.close();
      this.loadingDialog = undefined;

      let errorMessage = this.getErrorMessage(e.code);

      if (errorMessage) {
        this.dialogService.openMessageDialog('Unexpected Error', errorMessage);
      }
    }
  }

  public signInWithEmailPass(email: string, pass: string) {
    this.loadingDialog = this.dialogService.openLoadingDialog(`Signing In`, 'Please wait, we are processing your information');
    this.auth.signInWithEmailAndPassword(email, pass).then(res => {
      this.loadingDialog?.close();
      this.loadingDialog = undefined;
      // if (res.user?.emailVerified) {
      //   this.router.navigate(['/dashboard'])
      // } else {
      //   this.router.navigate(['/auth/email-not-verified'])
      // }
    }).then(res => {
      this.clearLoadingDialogs();
      this.router.navigate(['/dashboard']);
    }).catch(err => {
      this.loadingDialog?.close();
      this.loadingDialog = undefined;
      let message = this.getErrorMessage(err.code);
      if (message) {
        this.dialogService.openMessageDialog('Unexpected Error', message);
      }
    });
  }

  // TODO change this to use user service instead?
  public getUser(userId: string) {
    return this.afs.collection<TandemUser>('users').doc<TandemUser>(userId).snapshotChanges().pipe(map(doc => {
      if (doc.payload.exists) {
        const data = doc.payload.data() as any;
        const id = doc.payload.id;
        return {id, ...data};
      }
    }))
  }

  public verifyEmail(oobCode: string) {
    return this.auth.applyActionCode(oobCode);
    // .then(() => {
    //   // make a call to my backend that updates the user document's verification status (function will check if the user is actually verified)
    //   this.functions.httpsCallable('updateVerificationStatus').call(this,{ userId: 'user_id_here' }).subscribe(result => {
    //     console.log('Successfully updated document!');
    //   }, error => {
    //     console.error(error);
    //   })
    //   // this.http.
    // })
    // .catch(error => {
    // });
  }

  public resetPassword(oobCode: string, newPassword: string) {
    return this.auth.confirmPasswordReset(oobCode, newPassword);
  }

  public changePassword() {

  }

  public changeEmail() {

  }

  public resendEmailVerification(email: string) {
    const params = new HttpParams().set('email', email);
    return this.http.get<any>(`${this.serverPath}/resendEmailVerification`, {params: params});
    // return this.functions.httpsCallable('resendVerificationEmail').call(this, {email: email});
  }

  public getCurrentUser() {
    return this.auth.currentUser;
  }

  onLogout() {
    this.localStorageService.clearAllStorage();
    this.coreService.clearAllSubscriptions();
    return this.auth.signOut()
      .then(res => this.router.navigate(['/auth/login']).then(res => {
        this.headerService.resetConfig();
        this.$user.next(null);
        location.reload();
        this.dialogService.showAlert({
          title: 'Logged Out',
          content: 'Remember, we\'re here 24/7 for all your financial needs. Come back soon!',
          dismissTime: 7500,
          position: 'bottom'
        })
      }))
  }

  public async getIdToken() {
    const user = await this.auth.currentUser;
    if (user) {
      return user.getIdToken();
    }
    return null;
  }

  sendPasswordResetEmail(email: string) {
    const params = new HttpParams().set('email', email);
    return this.http.get<any>(`${this.serverPath}/sendPasswordReset`, {params: params});
  }

  private getErrorMessage(errorCode: string): string | undefined {
    switch (errorCode) {
      case 'auth/email-already-in-use':
        return "Looks like the email address you entered is already in use by another account. Please use a different email address to create an account or sign in with the email you entered.";
      case 'auth/invalid-email':
        return "Whoops, looks like that email is not a valid email address. Please make sure your email is correct and try again. If you need to create an account, click the 'Sign Up Here' link."
      case 'auth/operation-not-allowed':
        return "This sign-in method isn't currently enabled. Please check with your administrator or visit the Firebase Console under the Auth tab to enable it.";
      case 'auth/weak-password':
        return "Looks like you need a stronger password. Make sure your password has at least one capital letter, one special symbol, and is a minimum of 8 characters long.";
      case 'auth/account-exists-with-different-credential':
        return "It looks like an account with this email already exists but was registered using a different sign-up method. Please try signing in with the method you used to sign up.";
      case 'auth/auth-domain-config-required':
        return "Oops! We're missing some important configuration details to proceed. Please ensure that the 'authDomain' configuration is correctly set. You can find guidance on this in the Firebase Console under your project settings.";
      case 'auth/cancelled-popup-request':
        return undefined;
        // return "We noticed you've triggered several sign-in popups. To keep the site running smoothly, we only handle one sign-in popup at a time. Please try again, and remember, one popup at a time works best!";
      case 'auth/popup-closed-by-user':
        return undefined;
        // return "It looks like the sign-in popup was closed before completing the sign-in process. If you closed the popup by mistake, please try signing in again.";
      case 'auth/user-not-found':
        return 'Whoops, it looks like you don\'t have a registered account with us! Please verify the information you entered is correct and try again. If you need to create an account, click the \'Sign Up Here\' link.';
      case 'auth/wrong-password':
        return 'Whoops, looks like the password you entered is incorrect. Please try again.';
      default:
        return "Whoops! An unexpected error occurred, please try again.";
    }
  }

  clearLoadingDialogs() {
    this.loadingDialog?.close();
    this.creationLoadingDialog?.close();
    this.loadingDialog = undefined;
    this.creationLoadingDialog = undefined;
  }

  async deleteCurrentUser() {
    const user = await this.auth.currentUser;
    if (user) {
      user.delete().then(() => {
        console.log('User deleted successfully');
      }).catch((error) => {
        console.error('Error deleting user: ', error);
      });
    }
  }
}
