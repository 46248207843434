
<tandem-icon
    icon="question"
    class="relative " [tooltip]="text">
</tandem-icon>

<!--<div class="relative">-->
<!--  &lt;!&ndash; Question-mark Icon &ndash;&gt;-->
<!--  <tandem-icon-->
<!--    (mouseenter)="tooltipExpanded = true"-->
<!--    (mouseleave)="tooltipExpanded = false"-->
<!--    icon="question"-->
<!--    class="relative z-10">-->
<!--  </tandem-icon>-->

<!--  &lt;!&ndash; Tooltip &ndash;&gt;-->
<!--  <div class="absolute bottom-35 z-20">-->
<!--    <div class="relative inline-flex p-9 items-start gap-10 rounded-sm bg-primary w-500">-->
<!--      <h6 class="text-background">{{text}}</h6>-->
<!--      &lt;!&ndash; Tooltip Arrow &ndash;&gt;-->
<!--      <div class="w-15 h-15 rotate-45 bg-primary absolute bottom-3 top-27"></div>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->
