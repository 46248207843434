import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Observable, combineLatest, of } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import {AuthService} from "../services/auth.service";

@Injectable({
  providedIn: 'root'
})
export class NotSignedInGuard implements CanActivate {
  constructor(private afAuth: AngularFireAuth, private authService: AuthService, private router: Router) {}

  canActivate(): Observable<boolean> {
    return combineLatest([this.afAuth.authState, this.authService.$user]).pipe(
      take(1),
      map(([firebaseUser, userDocument]) => {
        if (!firebaseUser || !userDocument) {
          // User isn't signed in or user document isn't available, allow access
          return { allowAccess: true };
        } else {
          // User is signed in, pass along the hasNamePrompted property
          return { allowAccess: false, hasNamePrompted: userDocument.hasPromptedName };
        }
      }),
      tap(result => {
        if (!result.allowAccess) {
          // User is signed in and document is available
          if (result.hasNamePrompted) {
            this.router.navigate(['/dashboard']);
          } else {
            this.router.navigate(['/auth/additional-info']);
          }
        }
      }),
      map(result => result.allowAccess)
    );
  }
}
