<div *ngIf="!userIsPaid" (click)="openStripe()" class="flex flex-row w-full px-250 py-10 hover:cursor-pointer content-center items-center gap-10 bg-red">
  <p class="text-14 text-background">You're using the free version, upgrade to premium to unlock all the tools you need to have clarity in your finances.</p>
</div>
<ng-container *ngIf="headerConfig && !headerConfig.hideHeader">
  <header class="bg-primary/5 relative flex-initial px-40">
    <div class="margin-px-1 grid-container">
      <div class="w-full flex flex-row justify-between items-center">
        <div class="flex flex-col gap-15">
          <div *ngIf="headerConfig.isDashboard && showUserCircle" class="flex flex-row justify-end sm:pt-20 md:pt-30 lg:pt-50 xl:pt-75 relative">
            <img (click)="toggleUserDropdown()" class="profile-img border-4 border-primary rounded-full bg-background mb-30 h-60 w-60"
                 [src]="user?.photoURL || 'assets/images/profileForTandem.png'" alt="profile picture">
            <div class="absolute w-fit rounded-sm shadow-lg ring-1 ring-black ring-opacity-5 origin-top-right right-10 z-10 p-10 tandem-dropdown" style="top: 85%" [ngClass]="{'hidden': !displayUserDropdown}">
              <div class="py-1 flex flex-col gap-10" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                <div class="flex flex-row items-center gap-1 cursor-pointer" (click)="displayUserDropdown = false" [routerLink]="'auth/profile'" >
                  <tandem-icon class="duration-350 transition-quad-inout" icon="settings" color="black" [height]="20" [width]="20"></tandem-icon>
                  <a class="block px-4 py-5 text-sm" role="menuitem">My Account</a>
                </div>
                <div class="flex flex-row items-center gap-1 cursor-pointer" (click)="displayUserDropdown = false; onLogout()">
                  <tandem-icon class="duration-350 transition-quad-inout" icon="logout" color="black" [height]="20" [width]="20"></tandem-icon>
                  <a class="block px-4 py-5 text-sm" role="menuitem">Logout</a>
                </div>
              </div>
            </div>
          </div>
          <div class="flex flex-col gap-10 pb-10" *ngIf="headerConfig.isDashboard" [ngClass]="{'sm:pt-20 md:pt-30 lg:pt-50 xl:pt-75': !showUserCircle}">
            <div class="flex flex-row lg:gutter-gap-1">
              <h1 class="cols-6 lg:cols-5 xl:cols-9">Welcome Back {{headerConfig.title?.substring(13)}}!</h1>
              <div class="lg:hidden flex flex-col justify-start items-end cols-4">
                <tandem-button class="w-fit block mt-5" color="primary" icon="edit" [onClick]="onVisionChange.bind(this)">MY VISION</tandem-button>
              </div>
              <div class="hidden lg:flex cols-5 xl:cols-9 auto-cols-max flex-row gap-15 items-center">
                <h4 class="cols-1 xl:cols-2">My Vision</h4>
                <div class="cols-4 xl:cols-9 px-15 py-10 flex flex-row justify-between items-center border border-dark/20 rounded-sm h-auto">
                  <h5>{{user?.vision || 'No vision set'}}</h5>
                  <tandem-button class="ml-10" [onClick]="onVisionChange.bind(this)">edit</tandem-button>
                </div>
              </div>
            </div>
            <h4 class="cols-6 lg:cols-5 xl:cols-9">Active since {{user!.dateCreated.toDate() | date}}</h4>
            <h5 class="text-primary underline hover:cursor-pointer" (click)="showVideo()">Need a refresh?</h5>
          </div>
          <div *ngIf="!headerConfig.isDashboard" class="flex flex-col gap-20 sm:pt-20 md:pt-30 lg:pt-50 xl:pt-75">
            <span class="text-12-caps uppercase font-medium">
            <ng-container *ngFor="let crumb of headerConfig.breadcrumbs">{{crumb.label}} / </ng-container>
            </span>
            <h1>{{ headerConfig.title }}</h1>
            <h5>{{headerConfig.subtitle}}</h5>
          </div>
<!--          <h5 class="self-stretch">{{headerConfig.subtitle}}</h5>-->
        </div>
        <div *ngIf="!headerConfig.isDashboard && showUserCircle" class="flex flex-row justify-end sm:pt-20 md:pt-30 lg:pt-50 xl:pt-75 relative">
          <img (click)="toggleUserDropdown()" class="profile-img border-4 border-primary rounded-full z-50 bg-background mb-30 h-60 w-60"
               [src]="user?.photoURL || 'assets/images/profileForTandem.png'" alt="profile picture">
          <div class="absolute w-fit rounded-sm shadow-lg ring-1 ring-black ring-opacity-5 origin-top-right right-10 z-10 p-10 tandem-dropdown" style="top: 85%" [ngClass]="{'hidden': !displayUserDropdown}">
            <div class="py-1 flex flex-col gap-10" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
              <div class="flex flex-row items-center gap-1 cursor-pointer" (click)="displayUserDropdown = false" [routerLink]="'auth/profile'" >
                <tandem-icon class="duration-350 transition-quad-inout" icon="settings" color="black" [height]="20" [width]="20"></tandem-icon>
                <a class="block px-4 py-5 text-sm" role="menuitem">My Account</a>
              </div>
              <div class="flex flex-row items-center gap-1 cursor-pointer" (click)="displayUserDropdown = false; onLogout()">
                <tandem-icon class="duration-350 transition-quad-inout" icon="logout" color="black" [height]="20" [width]="20"></tandem-icon>
                <a class="block px-4 py-5 text-sm" role="menuitem">Logout</a>
              </div>
            </div>
          </div>
        </div>
<!--        <div *ngIf="headerConfig.isDashboard" class="w-fit flex flex-row gap-15 items-center">-->
<!--          <h4>My Vision</h4>-->
<!--          <div class="px-15 py-10 mr-40 flex flex-row justify-between items-center border border-dark/20 rounded-sm">-->
<!--            <h5 class="cols-6">{{user?.vision || 'No vision set'}}</h5>-->
<!--            <tandem-button [onClick]="onVisionChange.bind(this)">edit</tandem-button>-->
<!--          </div>-->
<!--        </div>-->
      </div>
      <!-- icons -->
    </div>
    <div>
      <svg
        class="w-full h-full scale-[-1] translate-y-1"
        fill="none"
        height="100%"
        viewBox="0 0 1440 74"
        width="100%"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          class="fill-background"
          d="M0 0H1440C1440 0 1189.5 40 720 40C250.5 40 0 0 0 0Z"
        />
      </svg>
      <div class="h-20 bg-background"></div>
    </div>
  </header>
</ng-container>
