import {Component, Input} from '@angular/core';

@Component({
  selector: 'tandem-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss']
})
export class TooltipComponent {

  @Input() text: string = '';

  tooltipExpanded = false;

  constructor() {
  }
}
