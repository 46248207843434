import {Component, OnInit} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {StripeService} from "ngx-stripe";
import {switchMap} from "rxjs";
import {environment} from "../../../../../environments/environment";

@Component({
  selector: 'tandem-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit {

  functionsPath = environment.functionsPath;

  constructor(
    private http: HttpClient,
    private stripeService: StripeService
  ) {}

  ngOnInit(): void {
  }

  checkout() {
    // Check the server.js tab to see an example implementation
    this.http.post<any>(`${this.functionsPath}/createCheckoutSession`, {})
      .pipe(
        switchMap(response => {
          return this.stripeService.redirectToCheckout({ sessionId: response.session.id })
        })
      )
      .subscribe(result => {
        // If `redirectToCheckout` fails due to a browser or network
        // error, you should display the localized error message to your
        // customer using `error.message`.
        if (result.error) {
          alert(result.error.message);
        }
      });
  }
}
