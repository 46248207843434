<div class="flex flex-col items-center justify-center">
  <form class="flex flex-col w-full h-full gap-20" (ngSubmit)="closeModal(true)">
    <h1 class="text-28">{{title}}</h1>
    <p style="max-width: 500px">{{message}}</p>
    <div class="flex flex-row justify-center gap-15">
      <tandem-button *ngIf="!hideCancel" theme="outline" [color]="confirmIsPrimary ? 'red' : 'primary'" [onClick]="closeModal.bind(this, false)">{{cancelText}}</tandem-button>
      <tandem-button *ngIf="!hideConfirm" type="submit" theme="outline" [color]="confirmIsPrimary ? 'primary' : 'red'" [onClick]="closeModal.bind(this, true)">{{confirmText}}</tandem-button>
    </div>
  </form>
</div>
