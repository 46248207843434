import {ChangeDetectorRef, Component, ElementRef, HostListener, OnDestroy, OnInit} from '@angular/core';
import 'firebase/compat/firestore';
import { Timestamp } from 'firebase/firestore';
import { NavigationEnd, Router, UrlSegment } from '@angular/router';
import {combineLatest, debounceTime, filter, fromEvent, Observable, Subject, Subscription, timer} from 'rxjs';
import {
  AlertState,
  DialogService,
} from './modules/tandem-core/services/dialog.service';
import {
  HeaderConfig,
  HeaderService,
} from './modules/tandem-core/services/header.service';
import {AuthService} from "./modules/auth/services/auth.service";
import {TandemUser} from "./modules/auth/models/tandem-user";
import {slideInAnimation} from "./modules/tandem-core/animations/route-animations";
import {map} from "rxjs/operators";
import {ThemingService} from "./modules/tandem-core/services/theming.service";
import {environment} from "../environments/environment";
import {SidenavState} from "./components/nav/sidenav2/sidenav2.component";

export interface TestObject {
  id?: string;
  name: string;
  population: number;
  dateCreated?: Timestamp;
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  animations: [
    slideInAnimation
  ]
})
export class AppComponent implements OnInit, OnDestroy {
  isExpanded: boolean = true;
  showSidenav: boolean = true;

  alertState: AlertState | null = null;
  private subscription: Subscription | null = null;
  private resizeSubscription: Subscription | null = null;


  headerConfig: HeaderConfig = {};
  user: TandemUser | null = null;
  displayHeader = false;

  sidenavToggled: Subject<void> = new Subject<void>();

  canDisplaySidenav: boolean = false;
  displaySidenav: boolean = true;
  displayNavbar: boolean = false;
  sidenavButtonClicked: boolean = false;
  displayUserDropdown: boolean = false;
  sidenavExpanded: boolean = false;
  collapseSidenav: Subject<boolean> = new Subject();
  openSidenav: Subject<boolean> = new Subject();

  screenIsSmall = false;

  sidenavState: SidenavState = 'collapsed';

  disableAllNav = false;


  constructor(
    private router: Router,
    private dialogService: DialogService,
    private headerService: HeaderService,
    private themingService: ThemingService,
    private auth: AuthService
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      }
    });
  }



  ngOnInit(): void {
    this.checkScreenSize();
    combineLatest([
      this.auth.$user,
      this.router.events.pipe(
        filter(event => event instanceof NavigationEnd), // only consider NavigationEnd events
        map(() => this.router.url) // extract the URL
      )
    ]).subscribe(([user, currentUrl]) => {
      this.user = user;
      if (!this.user) {
        this.themingService.setTheme(environment.defaultTheme);
      }
      this.disableAllNav = !user || (currentUrl.includes('auth/email-not-verified') || currentUrl.includes('auth/additional-info'));

      this.displayHeader = !!user || (!currentUrl.includes('auth/email-not-verified') && !currentUrl.includes('auth/additional-info'));


    });

    this.subscription = this.dialogService.$alertState.subscribe((newState) => {
      this.alertState = newState;
      if (newState) {
        timer(this.alertState?.dismissTime || 5000).subscribe(() => {
          this.dialogService.$alertState.next(null);
          this.alertState = null;
        });
      }
    });

    const scrollbarWidth = window.innerWidth - document.body.clientWidth + 'px';
    document.documentElement.style.setProperty('--scrollbar', scrollbarWidth);

    // Initial check for screen size
    this.checkScreenSize();

    // Listen for window resize events
    this.resizeSubscription = fromEvent(window, 'resize')
      .pipe(debounceTime(25)) // debounce it to prevent high frequency updates
      .subscribe(() => this.checkScreenSize());

    this.headerService
      .getConfig()
      .subscribe((config) => (this.headerConfig = config));
  }

  checkScreenSize(): void {
    if (!this.disableAllNav) {
      this.screenIsSmall = window.innerWidth < 1440;
      this.displaySidenav = window.innerWidth >= 1440;
      this.displayNavbar = window.innerWidth < 1440;
      if (this.screenIsSmall) {
        this.sidenavState = "closed";
      } else {
        this.sidenavState = 'collapsed';
      }
    } else {
      this.displaySidenav = false;
      this.displayHeader = false;
      this.displayNavbar = false;
    }
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
    this.resizeSubscription?.unsubscribe();
  }

  closeSidenav() {
    if (this.screenIsSmall) {
      this.sidenavState = 'closed';
      this.displaySidenav = false;
    } else {
      this.sidenavState = 'collapsed';
    }
  }

  protected readonly environment = environment;

  onSidenavToggled() {
    if (this.screenIsSmall) {
      this.sidenavState = 'closed';
      this.displaySidenav = false;
    } else {
      if (this.sidenavState === 'collapsed') {
        this.sidenavState = 'expanded';
      } else if (this.sidenavState === 'expanded') {
        this.sidenavState = 'collapsed';
      }
    }
  }

  onSidenavOpened($event: boolean) {
    this.sidenavState = 'expanded';
  }
}
