import {EventEmitter, Injectable, Input} from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";

export interface HeaderConfig {
  title?: string,
  subtitle?: string,
  breadcrumbs?: {label: string | undefined, route?: string}[],
  isDashboard?: boolean
  hideHeader?: boolean
}

@Injectable({
  providedIn: 'root'
})
export class HeaderService {

  private $headerConfig: BehaviorSubject<HeaderConfig> = new BehaviorSubject<HeaderConfig>({});
  public visionChanged: EventEmitter<void> = new EventEmitter<void>();

  constructor() { }

  public getConfig(): Observable<HeaderConfig> {
    return this.$headerConfig;
  }

  public setConfig(config: HeaderConfig): void {
    this.$headerConfig.next(config);
  }

  public setTitle(title: string): void {
    this.$headerConfig.next({...this.$headerConfig.value, title: title,});
  }

  public setSubtitle(subtitle: string): void {
    this.$headerConfig.next({...this.$headerConfig.value, subtitle: subtitle});
  }

  public setBreadcrumbs(breadcrumbs: {label: string, route?: string}[]): void {
    this.$headerConfig.next({...this.$headerConfig.value, breadcrumbs: breadcrumbs});
  }

  public setIsDashboard(isDashboard: boolean): void {
    this.$headerConfig.next({...this.$headerConfig.value, isDashboard: isDashboard});
  }

  public resetConfig() {
    this.$headerConfig.next({});
  }
}
