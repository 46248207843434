import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {UserService} from "../../user.service";
import {AuthService} from "../../services/auth.service";
import {TandemUser} from "../../models/tandem-user";
import {Router} from "@angular/router";

@Component({
  selector: 'tandem-additional-info',
  templateUrl: './additional-info.component.html',
  styleUrls: ['./additional-info.component.scss']
})
export class AdditionalInfoComponent implements OnInit {

  informationForm: FormGroup = new FormGroup<any>({})
  private user: TandemUser | null = null;
  constructor(private auth: AuthService,
              private fb: FormBuilder,
              private userService: UserService,
              private router: Router) {
  }
  ngOnInit(): void {
    this.auth.$user.subscribe(user => {
      this.user = user;
    })
    this.informationForm = this.fb.group({
      firstName: [null, Validators.required],
      lastName: [null, Validators.required]
    });
  }

  submit() {
    if (this.user) {
      this.userService.updateUserName(
        this.user.uid,
        this.informationForm.get('firstName')?.value,
        this.informationForm.get('lastName')?.value,
      ).then(res => {
        this.router.navigate(['/dashboard'])
      })
    }
  }
}
